import { TokenExpiredError } from '@/types/token-expired-error'
import { TaskQueue } from '@/types/workspace'
import { WorkerDetails } from '@/types/worker'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'

let twilioWorkspace: null | Twilio.TaskRouter.Workspace<{
    contact_uri: string
}> = null

export function useWorkspace() {
    const emitter = useTwilioAppEmitter()

    function fetchTaskQueues() {
        return new Promise<TaskQueue[]>((resolve, reject) => {
            if (!twilioWorkspace) {
                return reject('No workspace')
            }

            twilioWorkspace.taskqueues.fetch((error, values) => {
                if (error) {
                    return reject(error)
                }

                resolve(values.data)
            })
        })
    }

    async function init(token: string) {
        const taskQueue = await new Promise<TaskQueue[]>(
            async (resolve, reject) => {
                if (twilioWorkspace) {
                    twilioWorkspace.updateToken(token)
                    return fetchTaskQueues().then(resolve).catch(reject)
                }

                twilioWorkspace = new Twilio.TaskRouter.Workspace<{
                    contact_uri: string
                }>(token, import.meta.env.DEV)

                twilioWorkspace
                    .once('token.expired', () =>
                        reject(
                            new TokenExpiredError(
                                'Workspace AccessToken expired'
                            )
                        )
                    )
                    .once('error', reject)

                return fetchTaskQueues().then(resolve).catch(reject)
            }
        )

        addWorkspaceListeners()
        return taskQueue
    }

    function fetchWorkers() {
        return new Promise<WorkerDetails<{ contact_uri: string }>[]>(
            (resolve, reject) => {
                if (!twilioWorkspace) {
                    return reject('No workspace')
                }

                twilioWorkspace.workers.fetch((error, workers) => {
                    if (error) {
                        return reject(error)
                    }

                    resolve(workers.data)
                })
            }
        )
    }

    function addWorkspaceListeners() {
        twilioWorkspace
            ?.removeAllListeners()
            .on('token.expired', () => emitter.emit('tokenExpired'))
            .on('error', (error) =>
                emitter.emit('sdkError', { error, sdk: 'workspace' })
            )
            .on('disconnected', () =>
                emitter.emit('sdkDisconnected', 'workspace')
            )
            .on('connected', () => emitter.emit('sdkConnected', 'workspace'))

        emitter.on('refreshTokens', ({ workspaceToken }) => {
            twilioWorkspace?.updateToken(workspaceToken)
        })
    }

    function updateToken(workspaceToken: string) {
        twilioWorkspace?.updateToken(workspaceToken)
    }

    function destroy() {
        twilioWorkspace?.removeAllListeners()
        twilioWorkspace = null
    }

    return {
        init,
        destroy,
        updateToken,
        fetchWorkers,
    }
}

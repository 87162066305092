import { TaskSid } from '@/types/sid'
import { PhoneNumber } from '@/types/phoneNumber'

let FUNCTIONS_URL: string = ''

function init(functionsUrl: string) {
    FUNCTIONS_URL = functionsUrl
}

function constructUrl(...parts: string[]) {
    return `${FUNCTIONS_URL}${parts.join('/')}`
}

function toQueryString(params: Record<string, any>) {
    const urlParams = Object.entries(params).reduce(
        (acc: URLSearchParams, [key, value]) => {
            acc.append(key, value.toString())
            return acc
        },
        new URLSearchParams()
    )
    return `?${urlParams.toString()}`
}

async function get<T>(url: string, signal?: AbortSignal): Promise<T> {
    const res = await fetch(constructUrl(url), {
        method: 'GET',
        signal,
    })

    const body = await res.json()

    if (!res.ok) {
        return Promise.reject(body)
    }

    return body
}

async function post<T, P = Record<any, any>>(
    url: string,
    params: P,
    signal?: AbortSignal
): Promise<T> {
    const res = await fetch(constructUrl(url), {
        method: 'POST',
        signal,
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const body = await res.json()

    if (!res.ok) {
        return Promise.reject(body)
    }

    return body
}

export type AuthRequest = {
    refreshToken: string
}

export type AuthResponse = {
    workspaceToken: string
    workerToken: string
    deviceToken: string
    syncToken: string
    workerSid: string
    identity: string
    connectActivitySid: string
    disconnectActivitySid: string
    onACallActivitySid: string
    unavailableActivitySid: string
}

function authWorker(
    request: AuthRequest,
    signal?: AbortSignal
): Promise<AuthResponse> {
    return get<AuthResponse>(
        `/auth${toQueryString({ token: request.refreshToken })}`,
        signal
    )
}

type PhoneNumbersResponse = { phoneNumbers: PhoneNumber[] }

function fetchPhoneNumbers(): Promise<PhoneNumbersResponse> {
    return get<PhoneNumbersResponse>('/phone-numbers')
}

type MakeCallRequest = {
    to: string
    from: string
    worker_sid: string
    worker_identity: string
    customer_friendly_name: string
    worker_friendly_name: string
}

export type MakeCallResponse = {
    conference_sid: string
    customer_call_sid: string
    task_sid: TaskSid
}

function makeCall(request: MakeCallRequest): Promise<MakeCallResponse> {
    return post<MakeCallResponse>('/make-call', request)
}

type HoldParticipantRequest = {
    conference_sid: string
    call_sid: string
    hold: boolean
}

function holdParticipant(request: HoldParticipantRequest) {
    return post('/hold-participant', request)
}

type RemoveParticipantRequest = {
    conference_sid: string
    call_sid: string
    task_sid: string
}

function removeParticipant(request: RemoveParticipantRequest) {
    return post('/remove-participant', request)
}

type ColdTransferToQueueRequest = {
    call_sid: string
    queue_name: string
    worker_sid: string
    original_call_direction: string
}

function coldTransferToQueue(request: ColdTransferToQueueRequest) {
    return post('/cold-transfer-to-queue', request)
}

type OutsideColdTransferRequest = {
    call_sid: string
    phone_number: string
}

function outsideColdTransfer(request: OutsideColdTransferRequest) {
    return post('/outside-cold-transfer', request)
}

type OutsideWarmTransferRequest = {
    from: string
    to: string
    conference_sid: string
    task_sid: string
}

export type OutsideWarmTransferResponse = {
    label: string
    call_sid: string
}

function outsideWarmTransfer(
    request: OutsideWarmTransferRequest
): Promise<OutsideWarmTransferResponse> {
    return post('/outside-warm-transfer', request)
}

type AgentWarmTransferRequest = {
    task_sid: string
    to_sid: string
    to_identity: string
    from_identity: string
    from_friendly_name: string
    original_call_direction: string
    customer_identity: string
    agent_phone_number: string
}

function agentWarmTransfer(request: AgentWarmTransferRequest) {
    return post('/agent-warm-transfer', request)
}

function getConferenceStatusCallback() {
    return constructUrl('/conference-event', 'conference-status-callback')
}

type WarmTransferCallUrlRequest = {
    transferred_task_sid: string
    task_sid: string
}

function getWarmTransferCallUrl(request: WarmTransferCallUrlRequest) {
    return constructUrl(
        `/warm-transfer-conference-call${toQueryString(request)}`
    )
}

type ConferenceParticipantStatusCallbackRequest = {
    task_sid: string
    transferred_task_sid?: string
}

function getConferenceParticipantStatusCallback(
    request: ConferenceParticipantStatusCallbackRequest
) {
    return constructUrl(
        '/conference-event',
        `status-callback${toQueryString(request)}`
    )
}

export function useApi() {
    return {
        init,
        authWorker,
        fetchPhoneNumbers,
        makeCall,
        holdParticipant,
        removeParticipant,
        coldTransferToQueue,
        outsideColdTransfer,
        outsideWarmTransfer,
        agentWarmTransfer,
        getConferenceStatusCallback,
        getWarmTransferCallUrl,
        getConferenceParticipantStatusCallback,
    }
}

import { useApi, type AuthResponse, type AuthRequest } from './useApi'
import { Status } from '../types/status'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'
import { useExternalAuth } from './useExternalAuth'
import { parseJwt } from '@/jwt-refresh-token-utils'

const { authWorker } = useApi()

type WidgetAuthLoading = Status<'loading'>
type WidgetAuthLoaded = Status<'loaded'> & {
    data: AuthResponse
}
type WidgetAuthState = WidgetAuthLoaded | WidgetAuthLoading

let state: WidgetAuthState = {
    status: 'loading',
}

function setState(newState: WidgetAuthState) {
    state = newState
}

let abortController: AbortController | undefined

function setInitialState() {
    setState({
        status: 'loading',
    })
}

async function getAuthData(data: AuthRequest) {
    if (state.status === 'loaded') {
        return state.data
    }

    return fetchTokens(data)
}

async function fetchTokens(request: AuthRequest): Promise<AuthResponse> {
    if (abortController) {
        abortController.abort({ reason: 'aborted' })
    }

    abortController = new AbortController()
    const data = await authWorker(request, abortController?.signal)
    const newState: WidgetAuthState = {
        status: 'loaded',
        data,
    }
    setState(newState)
    abortController = undefined
    return data
}

function resetStateIfIdenityChanged(identity: string) {
    if (state.status === 'loaded' && state.data.identity !== identity) {
        setState({ status: 'loading' })
    }
}

export function useWidgetAuth() {
    async function init(workerInitData: AuthRequest) {
        const { identity } = parseJwt(workerInitData.refreshToken)
        setInitialState()
        resetStateIfIdenityChanged(identity)
        const authData = await getAuthData(workerInitData)
        addTokenExpiredListeners()
        return authData
    }

    function addTokenExpiredListeners() {
        const externalAuth = useExternalAuth()
        const emitter = useTwilioAppEmitter()
        emitter.on('tokenExpired', async () => {
            if (state.status !== 'loaded') {
                console.error(`Can't refresh tokens with not initialized state`)
                return
            }

            const data = await fetchTokens({
                refreshToken: externalAuth.getRefreshToken(),
            })
            emitter.emit('refreshTokens', data)
        })
    }

    function destroy() {
        setState({ status: 'loading' })
    }

    return {
        init,
        fetchTokens,
        destroy,
        get unavailableActivitySid() {
            if (state.status === 'loaded') {
                return state.data.unavailableActivitySid
            }
        },
        get identityData() {
            if (state.status === 'loaded') {
                return {
                    identity: state.data.identity,
                    sid: state.data.workerSid,
                }
            }

            return null
        },
    }
}

import { Reservation } from '@/types/reservation'
import { TaskAttributes } from '@/types/task-attributes'
import { TwilioError } from '@/types/twilio-error'
import { SyncDocumentData } from './useSync'
import EventEmitter from 'events'
import TypedEventEmitter from 'typed-emitter'
import { TaskSid } from '@/types/sid'
import { WorkerDetails } from '@/types/worker'
import { AuthResponse } from './useApi'

export type SDK = 'sync' | 'worker' | 'workspace' | 'device'

type TwilioAppEvents = {
    tokenExpired: () => any
    refreshTokens: (tokens: AuthResponse) => void
    sdkError: (params: { error: TwilioError; sdk?: SDK }) => any
    sdkDisconnected?: (sdk: SDK) => any
    sdkConnected?: (sdk: SDK) => any
    reservationCreated: (
        reservation: Reservation<TaskAttributes>,
        worker: WorkerDetails<{ contact_uri: string }>
    ) => any
    reservationUpdated: (
        reservation: Reservation<TaskAttributes>,
        worker: WorkerDetails<{ contact_uri: string }>
    ) => any
    reservationAccepted: (
        reservation: Reservation<TaskAttributes>,
        worker: WorkerDetails<{ contact_uri: string }>
    ) => any
    reservationRemoved: (reservation: Reservation<TaskAttributes>) => any
    syncDocUpdated: (params: {
        taskSid: TaskSid
        doc: SyncDocumentData
    }) => void
    workerUpdated: (worker: WorkerDetails<{ contact_uri: string }>) => void
}

class Emitter extends EventEmitter {
    emit(eventName: string | symbol, ...args: any[]) {
        if (import.meta.env.DEV) {
            // console.log('========================')
            // console.info(`twilio sdk: ${String(eventName)} | Args:`)
            // console.log(...args)
            // console.log('========================')
        }
        return super.emit(eventName, ...args)
    }
}

const twilioAppEmitter = new Emitter() as TypedEventEmitter<TwilioAppEvents>

export function useTwilioAppEmitter() {
    return twilioAppEmitter
}

const incomingAudio = new Audio()
incomingAudio.src =
    'https://sdk.twilio.com/js/client/sounds/releases/1.0.0/incoming.mp3?cache=2.6.1'
incomingAudio.muted = false
incomingAudio.loop = true

const outgoingAudio = new Audio()
outgoingAudio.src =
    'https://sdk.twilio.com/js/client/sounds/releases/1.0.0/outgoing.mp3?cache=2.6.1'
outgoingAudio.muted = false
outgoingAudio.loop = true

const isMuted = ref(false)

export function useRinger() {
    return {
        isMuted: computed(() => isMuted.value),
        toggleMuted() {
            isMuted.value = !isMuted.value
            incomingAudio.muted = isMuted.value
            outgoingAudio.muted = isMuted.value
        },
        playIncomingAudio() {
            incomingAudio.play()
        },
        stopIncomingAudio() {
            incomingAudio.pause()
            incomingAudio.currentTime = 0
        },
        playOutgoingAudio() {
            outgoingAudio.play()
        },
        stopOutgoingAudio() {
            outgoingAudio.pause()
            outgoingAudio.currentTime = 0
        },
        stopAudio() {
            incomingAudio.pause()
            incomingAudio.currentTime = 0
            outgoingAudio.pause()
            outgoingAudio.currentTime = 0
        },
    }
}

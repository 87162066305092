import EventEmitter from 'events'
import TypedEventEmitter from 'typed-emitter'
import {
    CallPayload,
    WorkerPayload,
    AcceptedAtPayload,
    CallDurationPayload,
    InputCallPayload,
    CallMutedPayload,
    QueueNamePayload,
    ExternalPhoneNumberPayload,
    WorkerParticipantPayload,
    ParticipantPayload,
    ActivityUpdatedPayload,
    CustomerCallSidPayload,
} from '@/embed/iframe-transport'
import { Contact } from '@/types/contact'
import { WorkerTask } from './useWorkerTask'

export type OutsideAppEvents = {
    inited: () => void
    disconnected: () => void
    connected: () => void
    error: (error: any) => void
    incomingCall: (payload: CallPayload) => void
    incomingCallCanceled: (payload: CallPayload & WorkerPayload) => void
    incomingCallAccepted: (
        payload: CallPayload &
            WorkerPayload &
            AcceptedAtPayload &
            CustomerCallSidPayload
    ) => void
    incomingCallRejected: (payload: CallPayload & WorkerPayload) => void
    incomingCallEnded: (
        payload: CallPayload & WorkerPayload & CallDurationPayload
    ) => void
    outgoingCall: (payload: CallPayload & WorkerPayload) => void
    outgoingCallCanceled: (payload: CallPayload & WorkerPayload) => void
    outgoingCallAccepted: (
        payload: CallPayload & WorkerPayload & AcceptedAtPayload
    ) => void
    outgoingCallRejected: (payload: CallPayload & WorkerPayload) => void
    outgoingCallEnded: (
        payload: CallPayload & WorkerPayload & CallDurationPayload
    ) => void
    inputCallDigit: (
        payload: CallPayload & WorkerPayload & InputCallPayload
    ) => void
    changeCallMuted: (
        payload: CallPayload & WorkerPayload & CallMutedPayload
    ) => void
    callTransferedToQueue: (
        payload: CallPayload & WorkerPayload & QueueNamePayload
    ) => void
    callTransferedToExternalPhoneNumber: (
        payload: CallPayload & WorkerPayload & ExternalPhoneNumberPayload
    ) => void
    addWorkerParticipant: (
        payload: CallPayload & WorkerPayload & WorkerParticipantPayload
    ) => void
    addExternalPhoneNumberParticipant: (
        payload: CallPayload & WorkerPayload & ExternalPhoneNumberPayload
    ) => void
    changeParticipantHold: (
        payload: CallPayload &
            WorkerPayload &
            ParticipantPayload & { hold: boolean }
    ) => void
    removeParticipant: (
        payload: CallPayload & WorkerPayload & ParticipantPayload
    ) => void
    inputOutgoingPhoneNumber: (payload: { phoneNumber: string }) => void
    contactClicked: (payload: { contact: Contact }) => void
    activityUpdated: (payload: WorkerPayload & ActivityUpdatedPayload) => void
}

const twilioAppEmitter = new EventEmitter() as TypedEventEmitter<
    OutsideAppEvents & { heightChanged: (payload: { height: number }) => void }
>

export function useOutsideAppEmitter() {
    return {
        workerTaskToCallPayload: (task: WorkerTask): CallPayload => {
            return {
                call: {
                    taskSid: task.taskSid,
                    taskQueue: task.taskQueue,
                    phoneNumber: task.phoneNumber,
                    contact: task.contact,
                },
            }
        },
        emitter: twilioAppEmitter,
    }
}

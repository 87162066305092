import { LoadingStatus } from '@/types/status'
import { Contact } from '@/types/contact'
import { PhoneNumber } from '@/types/phoneNumber'

export type OutboundDial = LoadingStatus & {
    contact: Contact | null
    to: string
    phoneNumber: PhoneNumber
}

export function useOutboundCallDial() {
    function setTo(dial: OutboundDial, to: string) {
        dial.to = to
        return dial
    }

    function setStatus(
        dial: OutboundDial,
        status: LoadingStatus['status']
    ): OutboundDial {
        dial.status = status
        return dial
    }

    function setContact(dial: OutboundDial, contact: Contact) {
        dial.contact = contact
        return dial
    }

    function setPhoneNumber(dial: OutboundDial, phoneNumber: PhoneNumber) {
        dial.phoneNumber = phoneNumber
        return dial
    }

    return {
        setStatus,
        setContact,
        setPhoneNumber,
        setTo,
    }
}

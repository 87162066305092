<script setup lang="ts">
import { WarmTransferState } from '@/composables/useAcceptedCall'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    state: DeepReadonly<WarmTransferState>
}>()

const val = ref<'phone-number' | 'agent'>('agent')

const phone = ref('')
const error = ref('')
const submitted = ref(false)
const selectedAgent = ref<null | {
    identity: string
    friendlyName: string
    sid: string
}>(null)

const isButtonDisabled = computed(() => {
    if (val.value === 'phone-number') {
        return !phone.value
    }

    if (val.value === 'agent') {
        return !selectedAgent.value
    }

    return false
})

const emit = defineEmits<{
    loadWorkers: []
    warmPhoneNumberTransfer: [string]
    warmAgentTransfer: [
        {
            friendlyName: string
            sid: string
            identity: string
        },
    ]
}>()

async function triggerTransfer() {
    submitted.value = true

    if (val.value === 'phone-number' && error.value) {
        return
    }

    if (val.value === 'phone-number') {
        return emit('warmPhoneNumberTransfer', phone.value)
    }

    if (val.value === 'agent' && selectedAgent.value) {
        emit('warmAgentTransfer', selectedAgent.value)
    }
}

const search = ref('')

const workers = computed(() => {
    return props.state.workers.filter((val) =>
        val.friendlyName
            .trim()
            .toLowerCase()
            .includes(search.value.trim().toLowerCase())
    )
})

emit('loadWorkers')

const isLoadingAgents = computed(() => {
    return (
        typeof props.state.status === 'string' &&
        props.state.status === 'loading'
    )
})

const isLoading = computed(() => {
    return (
        typeof props.state.submitStatus === 'string' &&
        props.state.submitStatus === 'loading'
    )
})
</script>
<template>
    <div class="flex flex-col gap-4 mt-4">
        <div class="flex flex-col gap-4">
            <div class="flex items-center gap-3">
                <RadioButton
                    :disabled="isLoading"
                    v-model="val"
                    value="agent"
                    name="forwardTo"
                    :label="val === 'agent' ? '' : 'Add Agent'"
                />
                <template v-if="val === 'agent'">
                    <input
                        v-model="search"
                        class="flex-grow input"
                        placeholder="Search by agent name"
                    />
                    <IconButton
                        :disabled="isLoadingAgents"
                        :icon="isLoadingAgents ? 'spinner' : 'reload'"
                        @click="$emit('loadWorkers')"
                    />
                </template>
            </div>
            <div
                class="flex flex-col h-36 overflow-auto"
                v-if="val === 'agent'"
            >
                <div
                    class="my-auto flex items-center gap-3 justify-center"
                    v-if="isLoadingAgents"
                >
                    <span class="text-sm text-black dark:text-white"
                        >Loading agents...</span
                    >
                    <SvgIcon class="h-4 w-4" name="spinner" />
                </div>
                <template v-else>
                    <template v-if="!workers.length && search">
                        <div
                            class="my-auto text-sm text-black dark:text-white flex flex-col gap-1 text-center"
                        >
                            <span>No agents found with search </span>
                            <span>"{{ search }}"</span>
                        </div>
                    </template>
                    <button
                        class="px-9 flex gap-2 items-center py-2 rounded-lg text-sm text-black dark:text-white font-light"
                        v-for="worker in workers"
                        :class="[
                            {
                                'bg-button-hover':
                                    selectedAgent?.sid === worker.sid,
                            },
                            isLoading || !worker.available
                                ? 'opacity-50 cursor-not-allowed'
                                : 'cursor-pointer hover:bg-button-hover',
                        ]"
                        :disabled="isLoading || !worker.available"
                        :key="worker.sid"
                        @click="
                            selectedAgent = {
                                identity: worker.identity,
                                sid: worker.sid,
                                friendlyName: worker.friendlyName,
                            }
                        "
                    >
                        <SvgIcon
                            class="h-4 w-4"
                            name="check"
                            :class="
                                selectedAgent?.identity === worker.identity
                                    ? 'opacity-100'
                                    : 'opacity-0'
                            "
                        />
                        <div class="h-8 flex items-center gap-2">
                            <div
                                class="h-1.5 w-1.5 rounded-full"
                                :class="{
                                    'bg-main-green':
                                        worker.activityName === 'Available',
                                    'bg-orange':
                                        worker.activityName === 'Unavailable',
                                    'bg-main-red':
                                        worker.activityName === 'Offline',
                                }"
                            />
                            <div class="flex flex-col text-left">
                                <span>
                                    {{ worker.friendlyName }}
                                </span>
                                <span class="text-xs text-text-gray">
                                    {{ worker.activityName }}
                                </span>
                            </div>
                        </div>
                    </button>
                </template>
            </div>
        </div>
        <div class="flex flex-col gap-3">
            <div class="flex">
                <RadioButton
                    :disabled="isLoading"
                    v-model="val"
                    value="phone-number"
                    name="forwardTo"
                    label="Add external participant"
                />
            </div>
            <div class="px-6">
                <PhoneInput
                    v-model="phone"
                    @validated="error = $event"
                    @enter="triggerTransfer"
                    :disabled="isLoading"
                    v-if="val === 'phone-number'"
                />
                <ErrorMessage
                    :status="'loaded'"
                    v-if="submitted && val === 'phone-number'"
                    :error-message="error"
                />
            </div>
        </div>
        <div class="flex flex-col">
            <Button
                class="h-8 text-sm relative font-normal w-auto mx-auto px-3"
                :disabled="isButtonDisabled || isLoading"
                @click="triggerTransfer"
            >
                <span :class="isLoading ? 'opacity-0' : 'opacity-100'"
                    >Add</span
                >
                <div
                    v-if="isLoading"
                    class="absolute left-1/2 transform -translate-x-1/2 top-2"
                >
                    <SvgIcon class="h-4 w-4" name="spinner" />
                </div>
            </Button>
            <ErrorMessage :status="state.status" />
        </div>
    </div>
</template>

import { LoadingStatus, Status } from '@/types/status'
import { SDK } from './useTwilioAppEmitter'
import { TwilioError } from '@/types/twilio-error'
import { ShortActivity } from '@/types/activity'
import { WorkerTask } from './useWorkerTask'
import { OutboundDial } from './useOutboundDialState'
import { PhoneNumber } from '@/types/phoneNumber'
import { AcceptedCall } from './useAcceptedCall'
import { Contact } from '@/types/contact'
import { useWidgetEmitter } from './useWidgetEmitter'

type SDKStatuses = Record<
    SDK,
    Status<'connected' | 'disconnected'> & {
        error: null | TwilioError
    }
>

export type WorkerState = {
    activity: ShortActivity & LoadingStatus
    name: string
    activities: Readonly<ShortActivity[]>
    taskQueues: Readonly<{ friendlyName: string; sid: String }[]>
    phoneNumbers: Readonly<PhoneNumber[]>
    content: null | WorkerTask | OutboundDial
}

type WidgetLoading = Status<'loading'> & {
    worker: null | { name: string; activity: ShortActivity & LoadingStatus }
    content: null | WorkerTask
}
type WidgetLoaded = Status<'loaded'> & WorkerState & SDKStatuses
type WidgetError = Status<'error'> & { error: string }

export type WidgetState = WidgetLoading | WidgetLoaded | WidgetError

export type WorkerInitParams = {
    name: string
    activity: ShortActivity
    activities: Readonly<ShortActivity[]>
    phoneNumbers: Readonly<PhoneNumber[]>
    taskQueues: Readonly<{ friendlyName: string; sid: String }>[]
}

export const useWidgetStore = defineStore('widgetState', () => {
    const state = reactive<WidgetState>({
        status: 'loading',
        worker: null,
        content: null,
    })

    const workerTask = computed(() => {
        if (state.status !== 'loaded' || !state.content) {
            return null
        }

        if ('to' in state.content) {
            return null
        }

        return state.content
    })

    return {
        state: readonly(state),
        initWorkerState(params: WorkerInitParams) {
            let worker: WidgetLoading['worker'] = null

            if (state.status === 'loading') {
                worker = state.worker
            }

            state.status = 'loaded'

            if (state.status === 'loaded') {
                state.activities = params.activities
                state.taskQueues = params.taskQueues
                state.content = state.content || null
                state.phoneNumbers = params.phoneNumbers

                if (worker) {
                    state.name = worker.name
                    state.activity = worker.activity
                } else {
                    state.name = params.name
                    state.activity = {
                        ...params.activity,
                        status: 'loaded',
                    }
                }

                /**
                 * TODO: On disconnected - show disconnected state
                 */
                state.sync = { status: 'connected', error: null }
                state.worker = { status: 'connected', error: null }
                state.workspace = { status: 'connected', error: null }
                state.device = { status: 'connected', error: null }
            }
        },
        setWorker(name: string, activity: ShortActivity) {
            if (state.status === 'loaded') {
                state.name = name
                state.activity = {
                    ...activity,
                    status: 'loaded',
                }
            }

            if (state.status === 'loading') {
                state.worker = {
                    name: name,
                    activity: {
                        ...activity,
                        status: 'loaded',
                    },
                }
            }
        },
        setGeneralError(error: string) {
            if (state.status === 'loading') {
                Object.assign(state, { status: 'error', error })
            }
        },
        setSdkDisconnected(sdk: SDK) {
            if (state.status === 'loaded' && state[sdk]) {
                state[sdk].status = 'disconnected'
            }
        },
        setSdkError(error: TwilioError, sdk: SDK) {
            if (state.status === 'loading') {
                Object.assign(state, { status: 'error', error })
            }

            if (state.status === 'loaded') {
                state[sdk] = {
                    ...state[sdk],
                    error,
                }
            }
        },
        setWorkerTask(workerTask: WorkerTask | null) {
            if (state.status === 'loaded' || state.status === 'loading') {
                state.content = workerTask
            }
        },
        setWorkerActitivy(
            activity: ShortActivity,
            status: LoadingStatus['status']
        ) {
            if (state.status !== 'loaded') {
                return
            }

            state.activity = {
                ...activity,
                status,
            }
        },
        toggleOutgoingCall(isOpened: boolean) {
            if (state.status !== 'loaded') {
                return
            }

            if (!isOpened) {
                state.content = null
                return
            }

            state.content = {
                contact: null,
                to: '',
                phoneNumber: state.phoneNumbers[0],
                status: 'none',
            }
        },
        updateOutgoingCallDial(
            updateFn: (dial: OutboundDial) => OutboundDial
        ): OutboundDial | null {
            if (state.status !== 'loaded' || !state.content) {
                return null
            }

            if ('taskSid' in state.content) {
                return null
            }

            const newOutgoingCall = updateFn(state.content)
            state.content = newOutgoingCall
            return newOutgoingCall
        },
        setContact(contact: Contact) {
            if (state.status !== 'loaded' || !state.content) {
                return
            }

            if (!state.content.contact) {
                state.content.contact = {
                    id: contact.id,
                    name: contact.name,
                    avatar: contact.avatar || null,
                    phoneNumber: contact.phoneNumber || '',
                }
            }

            state.content.contact.id = contact.id || null
            state.content.contact.name = contact.name || null
            state.content.contact.avatar = contact.avatar || null
            state.content.contact.phoneNumber =
                contact.phoneNumber || state.content.contact.phoneNumber
        },
        setOutboundContact({
            contact: { id, name, phoneNumber, avatar },
            fromPhoneNumber,
        }: {
            contact: Contact
            fromPhoneNumber?: string
        }) {
            if (state.status !== 'loaded') {
                return
            }

            const emitter = useWidgetEmitter()

            if (!state.content) {
                state.content = {
                    contact: {
                        id,
                        name,
                        phoneNumber,
                        avatar: avatar || null,
                    },
                    to: phoneNumber,
                    phoneNumber:
                        state.phoneNumbers.find(
                            (number) => number.phoneNumber === fromPhoneNumber
                        ) || state.phoneNumbers[0],
                    status: 'none',
                }
            }

            if ('to' in state.content && state.content.status !== 'loading') {
                state.content = {
                    ...state.content,
                    contact: {
                        id,
                        name,
                        phoneNumber,
                        avatar: avatar || null,
                    },
                    to: phoneNumber,
                    phoneNumber:
                        state.phoneNumbers.find(
                            (number) => number.phoneNumber === fromPhoneNumber
                        ) || state.phoneNumbers[0],
                }
            }

            if (fromPhoneNumber) {
                emitter.emit('makeOutboundCall', {
                    from: fromPhoneNumber,
                    to: phoneNumber,
                })
            }
        },
        updateAcceptedCall(
            updateFn: (acceptedCall: AcceptedCall) => AcceptedCall
        ): AcceptedCall | null {
            if (state.status !== 'loaded' || !state.content) {
                return null
            }

            if ('to' in state.content) {
                return null
            }

            if (!state.content.acceptedCall) {
                return null
            }

            const newAcceptedCall = updateFn(state.content.acceptedCall)
            state.content.acceptedCall = newAcceptedCall
            return newAcceptedCall
        },
        workerTask,
        activities: computed(() => {
            if (state.status !== 'loaded') {
                return []
            }

            return state.activities.filter(
                (activity) => activity.friendlyName !== 'Offline'
            )
        }),
        phoneNumbers: computed(() => {
            if (state.status !== 'loaded') {
                return []
            }

            return state.phoneNumbers
        }),
        availableTaskQueues: computed(() => {
            if (state.status !== 'loaded') {
                return []
            }

            return state.taskQueues.filter((queue) => {
                return queue.friendlyName !== 'Transfer Calls'
            })
        }),
        isAnySdkDisconnected: computed(() => {
            if (state.status === 'loaded') {
                return (
                    state.worker.status === 'disconnected' ||
                    state.sync.status === 'disconnected' ||
                    state.workspace.status === 'disconnected' ||
                    state.device.status === 'disconnected'
                )
            }

            return false
        }),
        latestState: computed(() => {
            return state
        }),
        activity: computed(() => {
            if (state.status !== 'loaded') {
                return null
            }

            return state.activity
        }),
        currentContact: computed(() => {
            if (state.status !== 'loaded' || !state.content) {
                return null
            }

            return state.content.contact
        }),
        setPhoneNumbers(phoneNumbers: PhoneNumber[]) {
            if (state.status !== 'loaded') {
                return
            }

            state.phoneNumbers = phoneNumbers
        },
    }
})

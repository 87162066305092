<script setup lang="ts">
const props = defineProps<{
    color?: 'green' | 'red'
    icon?: string
}>()

defineEmits<{
    click: []
}>()

function getButtonClass() {
    if (props.color === 'green') {
        return 'btn-green'
    }

    if (props.color === 'red') {
        return 'btn-red'
    }

    return 'btn-transparent'
}

const buttonClass = getButtonClass()
</script>
<template>
    <button
        :class="buttonClass"
        class="font-medium flex items-center justify-center gap-2.5 h-10 w-31.5 overflow-hidden"
        @click="$emit('click')"
    >
        <SvgIcon class="h-4 w-4" v-if="icon" :name="icon" />
        <slot />
    </button>
</template>

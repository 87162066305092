<script setup lang="ts">
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { WorkerState } from '@/composables/useWidgetStore'
import { Activity } from '@/types/activity'
import { type DeepReadonly } from 'vue'

const emitter = useWidgetEmitter()

defineProps<{
    workerState: DeepReadonly<WorkerState>
}>()

function updateWorkerActivity(activity: Activity) {
    emitter.emit('updateActivity', activity)
}
</script>
<template>
    <div class="p-4">
        <template v-if="!workerState.content">
            <WorkerIdle
                :state="workerState"
                @updateWorkerActivity="updateWorkerActivity"
            />
        </template>
        <template v-else-if="'to' in workerState.content">
            <OutboundDial :state="workerState.content" />
        </template>
        <template v-else-if="!workerState.content.acceptedCall">
            <WarmTransfer
                v-if="workerState.content.type === 'warm_transfer'"
                :task="workerState.content"
            />
            <IncomingCall
                v-else-if="workerState.content.type === 'incoming_call'"
                :task="workerState.content"
            />
            <OutboundCall
                v-else-if="workerState.content.type === 'outgoing_call'"
                :task="workerState.content"
            />
        </template>
        <template v-else>
            <AcceptedCall
                :state="workerState.content.acceptedCall"
                :task="workerState.content"
            />
        </template>
    </div>
</template>

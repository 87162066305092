<script setup lang="ts">
import { ColdTransferState } from '@/composables/useAcceptedCall'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    state: DeepReadonly<ColdTransferState>
}>()

const val = ref<'phone-number' | 'task-queue'>('task-queue')

const phone = ref('')
const error = ref('')
const submitted = ref(false)
const selectedTaskQueue = ref('')

const isButtonDisabled = computed(() => {
    if (val.value === 'phone-number') {
        return !phone.value
    }

    if (val.value === 'task-queue') {
        return !selectedTaskQueue.value
    }

    return false
})

const emit = defineEmits<{
    coldTransferToQueue: [string]
    coldTransferToPhoneNumber: [string]
}>()

async function triggerTransfer() {
    submitted.value = true

    if (val.value === 'phone-number' && error.value) {
        return
    }

    if (val.value === 'task-queue') {
        return emit('coldTransferToQueue', selectedTaskQueue.value)
    }

    if (val.value === 'phone-number') {
        emit('coldTransferToPhoneNumber', phone.value)
    }
}

const isLoading = computed(() => {
    return props.state.status === 'loading'
})
</script>
<template>
    <div class="flex flex-col gap-4 mt-4">
        <div class="flex flex-col gap-4">
            <div class="flex">
                <RadioButton
                    :disabled="isLoading"
                    v-model="val"
                    value="task-queue"
                    name="forwardTo"
                    label="Transfer to Task Queue"
                />
            </div>
            <div class="flex flex-col" v-if="val === 'task-queue'">
                <button
                    class="px-9 flex gap-2 items-center py-2 rounded-lg text-sm text-black dark:text-white font-light"
                    :class="[
                        {
                            'bg-button-hover':
                                selectedTaskQueue === queue.friendlyName,
                        },
                        isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'cursor-pointer hover:bg-button-hover',
                    ]"
                    :disabled="isLoading"
                    v-for="queue in state.taskQueues"
                    :key="queue.sid"
                    @click="selectedTaskQueue = queue.friendlyName"
                >
                    <SvgIcon
                        class="h-4 w-4"
                        name="check"
                        :class="
                            selectedTaskQueue === queue.friendlyName
                                ? 'opacity-100'
                                : 'opacity-0'
                        "
                    />
                    <div class="h-6 flex items-center">
                        {{ queue.friendlyName }}
                    </div>
                </button>
            </div>
        </div>
        <div class="flex flex-col gap-3">
            <div class="flex">
                <RadioButton
                    :disabled="isLoading"
                    v-model="val"
                    value="phone-number"
                    name="forwardTo"
                    label="Transfer to Phone Number"
                />
            </div>
            <div class="px-6">
                <PhoneInput
                    v-model="phone"
                    @validated="error = $event"
                    :disabled="isLoading"
                    @enter="triggerTransfer"
                    v-if="val === 'phone-number'"
                />
                <ErrorMessage
                    v-if="submitted && val === 'phone-number'"
                    :status="'loaded'"
                    :error-message="error"
                />
            </div>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-col gap-3">
                <Button
                    class="h-8 text-sm relative font-normal w-auto mx-auto px-3"
                    :disabled="isButtonDisabled || isLoading"
                    @click="triggerTransfer"
                >
                    <span :class="isLoading ? 'opacity-0' : 'opacity-100'"
                        >Transfer</span
                    >
                    <div
                        v-if="isLoading"
                        class="absolute left-1/2 transform -translate-x-1/2 top-2"
                    >
                        <SvgIcon class="h-4 w-4" name="spinner" />
                    </div>
                </Button>
                <span
                    class="text-sm font-light text-center px-10 text-not-active-btn-text"
                >
                    Transferring a call will end the call for you immediately.
                </span>
            </div>
            <ErrorMessage :status="state.status" />
        </div>
    </div>
</template>

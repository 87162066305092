<script lang="ts" setup>
const { isOnline, downlink, isSupported } = useNetwork()

const connectionType = computed(() => {
    if (!isOnline.value) {
        return 'offline'
    }

    if (typeof downlink.value !== 'undefined' && downlink.value < 2) {
        return 'poor'
    }

    return 'online'
})

const connectionTypeText = computed(() => {
    if (connectionType.value === 'poor') {
        return 'Poor connection'
    }

    if (connectionType.value === 'online') {
        return 'You are online'
    }

    if (connectionType.value === 'offline') {
        return 'You are offline'
    }

    return ''
})
</script>
<template>
    <Tooltip
        placement="left"
        class="flex-shrink-0"
        v-if="isSupported"
        :disabled="!connectionTypeText"
    >
        <div
            class="flex-shrink-0"
            :class="{
                'text-main-green': connectionType === 'online',
                'text-orange': connectionType === 'poor',
                'text-red-500': connectionType === 'offline',
            }"
        >
            <SvgIcon class="h-5 w-5" name="signal" />
        </div>
        <template #popper>
            <span>{{ connectionTypeText }}</span>
        </template>
    </Tooltip>
</template>

<script lang="ts" setup>
import { WorkerState } from '@/composables/useWidgetStore'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    workerState: DeepReadonly<WorkerState>
}>()

const workerTask = computed(() => {
    if (!props.workerState.content || 'to' in props.workerState.content) {
        return null
    }

    return props.workerState.content
})

const title = computed(() => {
    if (!workerTask.value) {
        return ''
    }

    if (workerTask.value?.acceptedCall) {
        return workerTask.value.phoneNumber.friendlyName
    }

    if (workerTask.value.type === 'outgoing_call') {
        return 'Outgoing call'
    }

    return 'Incoming call'
})

const acceptedDiffInSeconds = computed(() => {
    if (!workerTask.value?.acceptedCall) {
        return 0
    }

    return (Date.now() - workerTask.value.acceptedCall.acceptedTimestamp) / 1000
})
</script>
<template>
    <div
        class="flex justify-between items-center py-2.5 mx-4 border-b border-main-bg-stroke gap-2"
    >
        <div class="flex-shrink-0" v-if="!workerTask?.acceptedCall">
            <RingerButton />
        </div>
        <div class="w-9" v-else>
            <Timer :initial-time="acceptedDiffInSeconds" />
        </div>
        <OutboundDialPhoneNumber
            v-if="workerState.content && 'to' in workerState.content"
            :state="workerState.content"
            :phone-numbers="workerState.phoneNumbers"
        />
        <div
            class="flex-grow px-2 text-black dark:text-white text-center overflow-hidden overflow-ellipsis"
            v-else
        >
            <span class="text-sm font-light whitespace-nowrap">
                {{ title }}
            </span>
        </div>
        <div class="h-8 w-8 flex items-center justify-end">
            <Signal />
        </div>
    </div>
</template>

import { isTokenCloseToExpire } from '@/jwt-refresh-token-utils'

type ExternalAuthState = {
    refreshTokenUrl: string
    refreshToken: string
}

let state = reactive<ExternalAuthState>({
    refreshTokenUrl: '',
    refreshToken: '',
})

export function useExternalAuth() {
    async function init({
        refreshTokenUrl,
        refreshToken,
    }: {
        refreshTokenUrl: string
        refreshToken: string
    }) {
        if (!refreshTokenUrl) {
            throw Error('No refreshTokenUrl provided')
        }

        if (!refreshToken) {
            throw Error('refreshToken is required.')
        }

        state.refreshTokenUrl = refreshTokenUrl
        state.refreshToken = refreshToken

        if (!isTokenCloseToExpire(refreshToken)) {
            return
        }

        const res = await fetch(refreshTokenUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        })

        const body = await res.json()
        state.refreshToken = body.refreshToken

        if (!res.ok) {
            return Promise.reject(body)
        }
    }

    return {
        init,
        getRefreshToken: () => state.refreshToken,
        latestState: () => state,
    }
}

<script lang="ts" setup>
import Widget from './components/Widget.vue'
import { useApi } from './composables/useApi'
import { useOutsideAppEmitter } from './composables/useOutsideEmitter'
import { useTwilioApp } from './composables/useTwilioApp'
import { parseJwt } from './jwt-refresh-token-utils'
import { PhoneNumber } from '@/types/phoneNumber'

const initPayload = inject<{
    phoneNumbers: PhoneNumber[]
    functionsUrl: string
    refreshTokenUrl: string
    refreshToken: string
}>('initPayload')

if (!initPayload) {
    alert('No init payload injected')
    throw Error('No init payload injected')
}

const api = useApi()
api.init(initPayload.functionsUrl)
const twilioApp = useTwilioApp(initPayload.phoneNumbers)
const { identity, workerSid } = parseJwt(initPayload.refreshToken)

twilioApp.init({
    identity,
    worker_sid: workerSid,
    refreshTokenUrl: initPayload.refreshTokenUrl,
    refreshToken: initPayload.refreshToken,
})

const widgetRef = ref<InstanceType<typeof Widget>>()

const latestHeight = ref(0)
const { emitter } = useOutsideAppEmitter()

onMounted(() => {
    if (!widgetRef.value || !widgetRef.value.$el) {
        return
    }

    const rect = widgetRef.value.$el.getBoundingClientRect()
    latestHeight.value = rect.height
    emitter.emit('heightChanged', { height: latestHeight.value })

    const observer = new MutationObserver(() => {
        const rect = widgetRef.value!.$el.getBoundingClientRect()
        latestHeight.value = rect.height
        emitter.emit('heightChanged', { height: latestHeight.value })
    })

    observer.observe(widgetRef.value.$el, {
        childList: true,
        subtree: true,
    })
})
</script>
<template>
    <Widget ref="widgetRef" />
</template>

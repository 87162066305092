<script lang="ts" setup>
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import type { WorkerTask } from '@/composables/useWorkerTask'
import { Contact } from '@/types/contact'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    task: DeepReadonly<WorkerTask>
}>()

const isLoading = computed(() => props.task.status === 'loading')

const emitter = useWidgetEmitter()

function rejectTask() {
    emitter.emit('rejectTask', props.task.taskSid)
}

function acceptTask() {
    emitter.emit('acceptTask', props.task.taskSid)
}

function openContact(contact: Contact) {
    emitter.emit('contactClicked', contact)
}
</script>
<template>
    <div class="flex flex-col">
        <div class="flex flex-col items-center gap-2">
            <Avatar :avatar="task.contact.avatar" />
            <div class="flex flex-col items-center">
                <button
                    class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
                    v-if="task.contact.name"
                    @click="openContact(task.contact)"
                >
                    <span>{{ task.contact.name }}</span>
                    <SvgIcon class="h-4 w-4" name="open-in-new" />
                </button>
                <div
                    class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
                >
                    {{ task.contact.phoneNumber }}
                </div>
            </div>
            <div class="flex flex-col text-center">
                <span class="text-black dark:text-white text-sm font-light">
                    <span class="text-text-gray">to </span>
                    {{ task.phoneNumber.friendlyName }}
                </span>
                <span class="text-xs font-light text-text-gray">
                    {{ task.taskQueue }}</span
                >
            </div>
        </div>
        <div class="flex justify-between w-full mt-6">
            <Button
                @click="rejectTask()"
                :icon="isLoading ? 'spinner' : 'reject'"
                color="red"
                :disabled="isLoading"
            >
                Reject
            </Button>
            <Button
                @click="acceptTask()"
                :icon="isLoading ? 'spinner' : 'accept'"
                color="green"
                :disabled="isLoading"
            >
                Accept
            </Button>
        </div>
        <ErrorMessage :status="task.status" />
    </div>
</template>

<script lang="ts" setup>
import { AcceptedCall } from '@/composables/useAcceptedCall'
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { WorkerTask } from '@/composables/useWorkerTask'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    state: DeepReadonly<AcceptedCall>
    task: DeepReadonly<WorkerTask>
}>()

const isLoading = computed(() => {
    return props.state.status === 'loading'
})

const isActiveContentLoading = computed(() => {
    if (
        !props.state.activeContent ||
        typeof props.state.activeContent === 'string'
    ) {
        return false
    }

    return props.state.activeContent.status === 'loading'
})

const shouldShowRemoveParticipant = computed(() => {
    return props.state.participants.length > 1
})

const hasNoClientParticipant = computed(() => {
    return props.state.participants.every(
        (participant) => !participant.isClient
    )
})

const isDisabled = computed(() => {
    return isLoading.value || shouldShowRemoveParticipant.value
})

const emitter = useWidgetEmitter()

function toggleMuted() {
    emitter.emit('toggleMuted', props.task.taskSid)
}

function toggleDialpadOpened() {
    emitter.emit('toggleDialpadOpened', props.task.taskSid)
}

function inputDialpadValue(value: string) {
    emitter.emit('inputDialpadValue', value)
}

function endCall() {
    emitter.emit('endCall', props.task.taskSid)
}

function toggleParticipantHold(identity: string) {
    emitter.emit('toggleParticipantHold', props.task.taskSid, identity)
}

function toggleForwarding() {
    emitter.emit('toggleForwarding')
}

function toggleAddParticipant() {
    emitter.emit('toggleAddParticipant')
}

function coldTransferToPhoneNumber(phoneNumber: string) {
    emitter.emit('coldTransferToPhoneNumber', props.task.taskSid, phoneNumber)
}

function coldTransferToQueue(queue: string) {
    emitter.emit('coldTransferToQueue', props.task.taskSid, queue)
}

function loadWorkers() {
    emitter.emit('loadWorkers')
}

function warmPhoneNumberTransfer(phoneNumber: string) {
    emitter.emit('warmPhoneNumberTransfer', props.task.taskSid, phoneNumber)
}

function warmAgentTransfer(agent: {
    sid: string
    identity: string
    friendlyName: string
}) {
    emitter.emit('warmAgentTransfer', props.task.taskSid, agent)
}

function removeParticipant(identity: string) {
    emitter.emit('removeCallParticipant', props.task.taskSid, identity)
}

function contactClicked() {
    emitter.emit('contactClicked', props.task.contact)
}
</script>
<template>
    <div class="flex flex-col gap-1">
        <AcceptedCallParticipant
            v-for="participant in state.participants"
            :contact="task.contact"
            :participant="participant"
            :shouldShowRemoveParticipant="shouldShowRemoveParticipant"
            :isCurrentParticipantAdmin="state.isConferenceAdmin"
            :key="participant.identity"
            @contactClicked="contactClicked"
            @toggleHold="toggleParticipantHold($event)"
            @removeParticipant="removeParticipant(participant.identity)"
        />
        <div class="flex justify-between mt-3">
            <Tooltip placement="top" :disabled="isLoading">
                <IconButton
                    :disabled="isLoading"
                    color="green"
                    :icon="state.isMuted ? 'mic-off' : 'mic'"
                    @click="toggleMuted()"
                />
                <template #popper>
                    <span>{{
                        state.isMuted ? 'Unmute yourself' : 'Mute yourself'
                    }}</span>
                </template>
            </Tooltip>
            <div class="flex gap-2">
                <Tooltip
                    placement="top"
                    :disabled="isDisabled || hasNoClientParticipant"
                >
                    <IconButton
                        icon="add-participant"
                        @click="toggleAddParticipant()"
                        :active="
                            typeof state.activeContent !== 'string' &&
                            state.activeContent?.type === 'warm_transfer'
                        "
                        :disabled="
                            isDisabled ||
                            isActiveContentLoading ||
                            hasNoClientParticipant
                        "
                    />
                    <template #popper>
                        <span>Add participant to call</span>
                    </template>
                </Tooltip>
                <Tooltip placement="top" :disabled="isLoading">
                    <IconButton
                        @click="toggleDialpadOpened()"
                        :active="state.activeContent === 'dialpad'"
                        icon="dialpad"
                        :disabled="isLoading || isActiveContentLoading"
                    />
                    <template #popper>
                        <span>Show dialpad</span>
                    </template>
                </Tooltip>
                <Tooltip
                    placement="top"
                    :disabled="isDisabled || hasNoClientParticipant"
                >
                    <IconButton
                        icon="forward"
                        @click="toggleForwarding()"
                        :active="
                            typeof state.activeContent !== 'string' &&
                            state.activeContent?.type === 'cold_transfer'
                        "
                        :disabled="
                            isDisabled ||
                            isActiveContentLoading ||
                            hasNoClientParticipant
                        "
                    />
                    <template #popper>
                        <span>Forward call</span>
                    </template>
                </Tooltip>
            </div>
            <Tooltip
                placement="top"
                :disabled="isLoading || isActiveContentLoading"
            >
                <IconButton
                    :disabled="isLoading || isActiveContentLoading"
                    @click="endCall()"
                    color="red"
                    :icon="isLoading ? 'spinner' : 'reject'"
                />
                <template #popper>
                    <span>End call</span>
                </template>
            </Tooltip>
        </div>
        <template v-if="state.activeContent">
            <Dialpad
                v-if="state.activeContent === 'dialpad'"
                :state="state.dialpad"
                @input-dialpad-value="inputDialpadValue($event)"
            />
            <ForwardCall
                v-else-if="state.activeContent.type === 'cold_transfer'"
                :state="state.activeContent"
                @cold-transfer-to-phone-number="coldTransferToPhoneNumber"
                @cold-transfer-to-queue="coldTransferToQueue"
            />
            <AddCallParticipant
                v-else-if="state.activeContent.type === 'warm_transfer'"
                :state="state.activeContent"
                @load-workers="loadWorkers"
                @warmPhoneNumberTransfer="warmPhoneNumberTransfer"
                @warm-agent-transfer="warmAgentTransfer"
            />
        </template>
        <ErrorMessage :status="props.state.status" />
    </div>
</template>

import { differenceInSeconds } from 'date-fns'

export function isTokenValid(token: string) {
    const { exp, workerSid, identity } = parseJwt(token)

    const isExpired = exp * 1000 - Date.now() <= 0

    return !!workerSid && !!identity && !isExpired
}

export function parseJwt(token: string): {
    exp: number
    workerSid: string
    identity: string
} {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

export function isTokenCloseToExpire(token: string) {
    const thresholdInSeconds = 10
    const { exp } = parseJwt(token)

    return differenceInSeconds(exp * 1000, Date.now()) <= thresholdInSeconds
}

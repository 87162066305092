import type { PhoneNumber } from '../types/phoneNumber'
import type { ShortActivity } from '../types/activity'
import type { Contact } from '../types/contact'
import type { TaskSid } from '../types/sid'
import type { InitPayload } from '../types/init'

type BaseAction<T> = { type: T }
type ActionWithPayload<T = any, P = any> = BaseAction<T> & { payload: P }

/**
 * Widget init actions
 */
type WidgetReady = BaseAction<'ready'>
type WidgetInited = BaseAction<'inited'>
type WidgetDisconnected = BaseAction<'widgetDisconnected'>
type WidgetConnected = BaseAction<'widgetConnected'>
type WidgetError = ActionWithPayload<'widgetError', { error: any }>

export type CallDetail = {
    taskSid: TaskSid
    phoneNumber: PhoneNumber
    contact: Contact
    taskQueue: string
}

export type WorkerDetails = {
    identity: string
    sid: string
    friendlyName: string
}

/**
 * Incoming call actions
 */
export type CallPayload = { call: CallDetail }
type IncomingCall = ActionWithPayload<'incomingCall', CallPayload>
export type WorkerPayload = { worker: WorkerDetails }
type IncomingCallCanceled = ActionWithPayload<
    'incomingCallCanceled',
    CallPayload & WorkerPayload
>

export type ActivityUpdatedPayload = {
    previousActivity: ShortActivity
    newActivity: ShortActivity
}
type WorkerActivityUpdated = ActionWithPayload<
    'workerActivityUpdated',
    ActivityUpdatedPayload & WorkerPayload
>
export type AcceptedAtPayload = { acceptedAt: number }
export type CustomerCallSidPayload = { customerCallSid: null | string }
type IncomingCallAccepted = ActionWithPayload<
    'incomingCallAccepted',
    CallPayload & WorkerPayload & AcceptedAtPayload & CustomerCallSidPayload
>
export type CallDurationPayload = { durationSeconds: number }
type IncomingCallEnded = ActionWithPayload<
    'incomingCallEnded',
    CallPayload & WorkerPayload & CallDurationPayload
>
type IncomingCallRejected = ActionWithPayload<
    'incomingCallRejected',
    CallPayload & WorkerPayload
>
/**
 * Outgoing call actions
 */
type OutgoingCall = ActionWithPayload<
    'outgoingCall',
    CallPayload & WorkerPayload
>
type OutgoingCallAccepted = ActionWithPayload<
    'outgoingCallAccepted',
    CallPayload & WorkerPayload & AcceptedAtPayload
>
type OutgoingCallRejected = ActionWithPayload<
    'outgoingCallRejected',
    CallPayload & WorkerPayload
>
type OutgoingCallCanceled = ActionWithPayload<
    'outgoingCallCanceled',
    CallPayload & WorkerPayload
>
type OutgoingCallEnded = ActionWithPayload<
    'outgoingCallEnded',
    CallPayload & WorkerPayload & CallDurationPayload
>
/**
 * Call actions
 */
export type InputCallPayload = { latestDigit: string; entered: string }
type InputCallDigit = ActionWithPayload<
    'inputCallDigit',
    CallPayload & WorkerPayload & InputCallPayload
>
export type CallMutedPayload = { isMuted: boolean }
type ChangeCallMuted = ActionWithPayload<
    'changeCallMuted',
    CallPayload & WorkerPayload & CallMutedPayload
>
/**
 * Cold transfer actions
 */
export type QueueNamePayload = { transferToQueue: string }
type CallTransferToQueue = ActionWithPayload<
    'callTransferedToQueue',
    CallPayload & WorkerPayload & QueueNamePayload
>
export type ExternalPhoneNumberPayload = { externalPhoneNumber: string }
type CallTransferToExternalPhoneNumber = ActionWithPayload<
    'callTransferedToExternalPhoneNumber',
    CallPayload & WorkerPayload & ExternalPhoneNumberPayload
>
/**
 * Conference actions
 */
export type WorkerParticipantPayload = { addedWorker: WorkerDetails }
type AddWorkerParticipant = ActionWithPayload<
    'addWorkerParticipant',
    CallPayload & WorkerPayload & WorkerParticipantPayload
>
type AddExternalPhoneNumberParticipant = ActionWithPayload<
    'addExternalPhoneNumberParticipant',
    CallPayload & WorkerPayload & ExternalPhoneNumberPayload
>
export type Participant = {
    type: 'worker' | 'client' | 'external'
    identity: string
}
export type ParticipantPayload = { participant: Participant }
type ChangeParticipantHold = ActionWithPayload<
    'changeParticipantHold',
    CallPayload & WorkerPayload & ParticipantPayload & { hold: boolean }
>
type RemoveParticipant = ActionWithPayload<
    'removeParticipant',
    CallPayload & WorkerPayload & ParticipantPayload
>
/**
 * Extra actions
 */
type InputOutgoingPhoneNumber = ActionWithPayload<
    'inputOutgoingPhoneNumber',
    {
        phoneNumber: string
    }
>
type WidgetHeightChanged = ActionWithPayload<
    'widgetHeightChanged',
    { height: number }
>
type ContactClicked = ActionWithPayload<'contactClicked', { contact: Contact }>

type AppAction =
    | WidgetReady
    | WidgetInited
    | WidgetDisconnected
    | WidgetConnected
    | WidgetError
    | WorkerActivityUpdated
    | InputCallDigit
    | ChangeCallMuted
    | WidgetHeightChanged
    | IncomingCall
    | IncomingCallCanceled
    | IncomingCallAccepted
    | InputOutgoingPhoneNumber
    | IncomingCallEnded
    | IncomingCallRejected
    | OutgoingCall
    | OutgoingCallCanceled
    | OutgoingCallAccepted
    | OutgoingCallEnded
    | OutgoingCallRejected
    | CallTransferToQueue
    | CallTransferToExternalPhoneNumber
    | AddWorkerParticipant
    | AddExternalPhoneNumberParticipant
    | ChangeParticipantHold
    | RemoveParticipant
    | ContactClicked

export function postAppAction(
    url: string,
    action: AppAction,
    target?: WindowProxy
) {
    return postAction(url, action, target)
}

export function decodeAppAction(actionString: string) {
    return decodeAction<AppAction>(actionString)
}

type SetContact = ActionWithPayload<'setContact', { contact: Contact | null }>
type SetOutboundContact = ActionWithPayload<
    'setOutboundContact',
    { contact: Contact; fromPhoneNumber?: string }
>
type SetPhoneNumbers = ActionWithPayload<
    'setPhoneNumbers',
    { phoneNumbers: PhoneNumber[] }
>
type SetContactLoading = BaseAction<'setContactLoading'>
type EmbedInit = ActionWithPayload<'init', InitPayload>

type EmbedAction =
    | EmbedInit
    | SetContact
    | SetOutboundContact
    | SetContactLoading
    | SetPhoneNumbers

export function postEmbedAction(
    url: string,
    action: EmbedAction,
    target: WindowProxy
) {
    return postAction(url, action, target)
}

export function decodeEmbedAction(actionString: string) {
    return decodeAction<EmbedAction>(actionString)
}

function postAction<A>(
    url: string,
    action: A,
    target: WindowProxy = window.parent
) {
    target.postMessage(JSON.stringify(action), url)
}

function decodeAction<P>(actionString: string): null | P {
    try {
        return JSON.parse(actionString)
    } catch (e) {
        return null
    }
}

<script lang="ts" setup>
import { useRinger } from '@/composables/useRinger'

const { isMuted, toggleMuted } = useRinger()
</script>
<template>
    <Tooltip placement="right">
        <IconButton
            :icon="isMuted ? 'notifications-off' : 'notifications'"
            @click="toggleMuted()"
        />
        <template #popper>
            <span>{{
                isMuted
                    ? 'Turn on sound notifications'
                    : 'Turn off sound notifications'
            }}</span>
        </template>
    </Tooltip>
</template>

import { TwilioError } from '@/types/twilio-error'
import EventEmitter from 'events'
import TypedEmitter from 'typed-emitter'
import { ShortActivity } from '@/types/activity'
import { PhoneNumber } from '@/types/phoneNumber'
import { TaskSid } from '@/types/sid'
import { Contact } from '@/types/contact'

type WidgetError = TwilioError & {
    sdk: 'worker' | 'workspace' | 'device'
}

export type WidgetEmitterEvents = {
    reconnect: () => void
    // Incoming task events
    acceptTask: (taskSid: TaskSid) => void
    rejectTask: (taskSid: TaskSid) => void
    endCall: (taskSid: TaskSid) => void
    cancelCall: (taskSid: TaskSid) => void
    // Outgoing dial events
    toggleOutgoingDial: (isOpened: boolean) => void
    selectOutgoingPhoneNumber: (phoneNumber: PhoneNumber) => void
    inputOutgoingDialNumber: (value: string) => void
    makeOutboundCall: (params: { to: string; from: string }) => void
    // Worker Idle events
    updateActivity: (activity: ShortActivity) => void
    //Accepted call events
    inputDialpadValue: (value: string) => void
    toggleMuted: (taskSid: TaskSid) => void
    toggleDialpadOpened: (taskSid: TaskSid) => void
    toggleParticipantHold: (taskSid: TaskSid, identity: string) => void
    toggleForwarding: () => void
    toggleAddParticipant: () => void
    loadWorkers: () => void
    warmPhoneNumberTransfer: (taskSid: TaskSid, phoneNumber: string) => void
    warmAgentTransfer: (
        taskSid: TaskSid,
        agent: { friendlyName: string; sid: string; identity: string }
    ) => void
    coldTransferToPhoneNumber: (taskSid: TaskSid, phoneNumber: string) => void
    coldTransferToQueue: (taskSid: TaskSid, queueSid: string) => void
    removeCallParticipant: (taskSid: TaskSid, identity: string) => void

    errorHappened: (error: WidgetError) => void
    toggleHold: (payload: {
        isOnHold: boolean
        reservationSid: string
        callSid: string
        conferenceSid: string
        identity: string
    }) => void
    addCallParticipant: (payload: { taskSid: string; identity: string }) => void
    setContact: (payload: Contact) => void
    setPhoneNumbers: (payload: PhoneNumber[]) => void
    setOutboundContact: (payload: Contact, fromPhoneNumber?: string) => void
    contactClicked: (contact: Contact) => void
    showSwitchToAvailableAlert: () => void
}

class Emitter extends EventEmitter {
    emit(eventName: string | symbol, ...args: any[]) {
        if (import.meta.env.DEV) {
            // console.log('========================')
            // console.info(`Emitted: ${String(eventName)} | Args:`)
            // console.log(...args)
            // console.log('========================')
        }
        return super.emit(eventName, ...args)
    }
}

const widgetEmitter = new Emitter() as TypedEmitter<WidgetEmitterEvents>

export function useWidgetEmitter() {
    return widgetEmitter
}

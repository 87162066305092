<script lang="ts" setup>
const props = defineProps<{
    name: string
}>()

const symbolId = computed(() => `#icon-${props.name}`)
</script>
<template>
    <svg aria-hidden="true">
        <use :href="symbolId" />
    </svg>
</template>

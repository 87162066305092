import 'virtual:svg-icons-register'
import { createApp } from 'vue'
import './style.css'
import 'floating-vue/dist/style.css'
import { decodeEmbedAction, postAppAction } from '@/embed/iframe-transport'
import App from './App.vue'
import { createPinia } from 'pinia'
import FloatingVue from 'floating-vue'
import { useApi } from './composables/useApi'
import { useOutsideAppEmitter } from './composables/useOutsideEmitter'
import { useWidgetEmitter } from './composables/useWidgetEmitter'
import { InitPayload } from '@/types/init'

async function init(payload: InitPayload, requester: string) {
    const { emitter } = useOutsideAppEmitter()
    const pinia = createPinia()
    const app = createApp(App)
    const api = useApi()

    api.init(payload.functionsUrl)

    app.use(pinia).use(FloatingVue, {
        instantMove: true,
        distance: 0,
        themes: {
            custom: {
                $extend: 'tooltip',
                $resetCss: true,
                distance: 6,
            },
            danger: {
                $extend: 'tooltip',
                $resetCss: true,
                distance: 6,
            },
        },
    })
    app.provide('initPayload', payload)
    app.mount('#app')

    const postAction = (payload: Parameters<typeof postAppAction>[1]) => {
        postAppAction(requester, payload)
    }

    emitter
        .on('inited', () => {
            postAction({ type: 'inited' })
        })
        .on('disconnected', () => {
            postAction({ type: 'widgetDisconnected' })
        })
        .on('connected', () => {
            postAction({
                type: 'widgetConnected',
            })
        })
        .on('error', (error: any) => {
            postAction({
                type: 'widgetError',
                payload: { error },
            })
        })
        .on('heightChanged', (payload) => {
            postAction({
                type: 'widgetHeightChanged',
                payload,
            })
        })
        .on('incomingCall', (payload) => {
            postAction({
                type: 'incomingCall',
                payload,
            })
        })
        .on('incomingCallCanceled', (payload) => {
            postAction({
                type: 'incomingCallCanceled',
                payload,
            })
        })
        .on('incomingCallAccepted', (payload) => {
            postAction({
                type: 'incomingCallAccepted',
                payload,
            })
        })
        .on('incomingCallRejected', (payload) => {
            postAction({
                type: 'incomingCallRejected',
                payload,
            })
        })
        .on('incomingCallEnded', (payload) => {
            postAction({
                type: 'incomingCallEnded',
                payload,
            })
        })
        .on('outgoingCall', (payload) => {
            postAction({
                type: 'outgoingCall',
                payload,
            })
        })
        .on('outgoingCallCanceled', (payload) => {
            postAction({
                type: 'outgoingCallCanceled',
                payload,
            })
        })
        .on('outgoingCallAccepted', (payload) => {
            postAction({
                type: 'outgoingCallAccepted',
                payload,
            })
        })
        .on('outgoingCallRejected', (payload) => {
            postAction({
                type: 'outgoingCallRejected',
                payload,
            })
        })
        .on('outgoingCallEnded', (payload) => {
            postAction({
                type: 'outgoingCallEnded',
                payload,
            })
        })
        .on('inputCallDigit', (payload) => {
            postAction({
                type: 'inputCallDigit',
                payload,
            })
        })
        .on('changeCallMuted', (payload) => {
            postAction({
                type: 'changeCallMuted',
                payload,
            })
        })
        .on('callTransferedToQueue', (payload) => {
            postAction({
                type: 'callTransferedToQueue',
                payload,
            })
        })
        .on('callTransferedToExternalPhoneNumber', (payload) => {
            postAction({
                type: 'callTransferedToExternalPhoneNumber',
                payload,
            })
        })
        .on('addWorkerParticipant', (payload) => {
            postAction({
                type: 'addWorkerParticipant',
                payload,
            })
        })
        .on('addExternalPhoneNumberParticipant', (payload) => {
            postAction({
                type: 'addExternalPhoneNumberParticipant',
                payload,
            })
        })
        .on('changeParticipantHold', (payload) => {
            postAction({
                type: 'changeParticipantHold',
                payload,
            })
        })
        .on('removeParticipant', (payload) => {
            postAction({
                type: 'removeParticipant',
                payload,
            })
        })
        .on('inputOutgoingPhoneNumber', (payload) => {
            postAction({
                type: 'inputOutgoingPhoneNumber',
                payload,
            })
        })
        .on('contactClicked', (payload) => {
            postAction({
                type: 'contactClicked',
                payload,
            })
        })
        .on('activityUpdated', (payload) => {
            postAction({
                type: 'workerActivityUpdated',
                payload,
            })
        })
}

function initMessenger() {
    const widgetEmitter = useWidgetEmitter()
    const { requester } = Object.fromEntries(
        window.location.search
            .replace('?', '')
            .split('&')
            .map((item) => item.split('='))
    )

    postAppAction(requester || '*', { type: 'ready' })

    window.addEventListener('message', (event) => {
        const data = decodeEmbedAction(event.data)

        if (!data) {
            return
        }

        if (data.type === 'init') {
            return init(data.payload, requester || '*')
        }

        if (data.type === 'setContact' && data.payload.contact) {
            return widgetEmitter.emit('setContact', data.payload.contact)
        }

        if (data.type === 'setPhoneNumbers') {
            return widgetEmitter.emit(
                'setPhoneNumbers',
                data.payload.phoneNumbers
            )
        }

        if (data.type === 'setOutboundContact') {
            return widgetEmitter.emit(
                'setOutboundContact',
                data.payload.contact,
                data.payload.fromPhoneNumber
            )
        }
    })
}

initMessenger()

import { TokenExpiredError } from '@/types/token-expired-error'
import { TwilioError } from '@/types/twilio-error'
import { Call, Device } from '@twilio/voice-sdk'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'

let twilioDevice: null | Device = null
let activeCall: null | Call = null

export type DeviceEvents = {
    error: (...args: any[]) => any
    incoming: (...args: any[]) => any
    destroyed: (...args: any[]) => any
    unregistered: (...args: any[]) => any
    registering: (...args: any[]) => any
    registered: (...args: any[]) => any
    tokenWillExpire: (...args: any[]) => any
}

export function useDevice() {
    const emitter = useTwilioAppEmitter()

    async function init(token: string) {
        await new Promise<void>(async (resolve, reject) => {
            if (twilioDevice) {
                return twilioDevice.updateToken(token)
            }

            twilioDevice = new Device(token, {
                closeProtection: true,
                logLevel: 0,
                // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
                // providing better audio quality in restrained network conditions.
                codecPreferences: ['opus', 'pcmu'] as any,
            })

            twilioDevice.audio?.incoming(false)

            twilioDevice
                .once('error', (e: TwilioError) => {
                    // 20104 is access token expired error
                    if (e.code === 20104) {
                        return reject(
                            new TokenExpiredError('Device AccessToken expired')
                        )
                    }

                    reject(e)
                })
                .once('tokenWillExpire', () =>
                    reject(
                        new TokenExpiredError(
                            'Device AccessToken about to expire'
                        )
                    )
                )

            if (
                twilioDevice.state === Device.State.Registered ||
                twilioDevice.state === Device.State.Registering
            ) {
                return resolve()
            }

            twilioDevice.register().then(resolve).catch(reject)
        })
        addDeviceListeners()
    }

    function addDeviceListeners() {
        ;(window as any).device = twilioDevice
        twilioDevice
            ?.removeAllListeners()
            .on('tokenWillExpire', () => emitter.emit('tokenExpired'))
            .on('error', (error) => {
                console.log('DEVICE ERROR =>', error)
                // 20104 is access token expired error
                if (error.code === 20104) {
                    return emitter.emit('tokenExpired')
                }

                emitter.emit('sdkError', {
                    error: { message: error.message, code: error.code },
                    sdk: 'device',
                })
            })
            .on('connected', () => emitter.emit('sdkConnected', 'device'))
            .on('disconnected', () => emitter.emit('sdkDisconnected', 'device'))
            .on('incoming', (call: Call) => {
                console.log('INCOMING CALL =>', call)
                activeCall = call

                if (call.customParameters.get('__accept') === 'true') {
                    try {
                        call.accept()
                    } catch (err) {
                        console.log('ERROR =>', err)
                    }
                }

                call.on('cancel', () => {
                    activeCall = null
                })

                call.on('disconnect', (_call) => {
                    activeCall = null
                })

                call.on('error', (error) => {
                    activeCall = null
                    console.log('Call error.', error)
                })

                call.on('reject', () => {
                    activeCall = null
                })
            })

        emitter.on('refreshTokens', ({ deviceToken }) => {
            twilioDevice?.updateToken(deviceToken)
        })
    }

    function sendDigits(value: string) {
        if (activeCall) {
            activeCall.sendDigits(value)
        }
    }

    function toggleMuted(isMuted: boolean) {
        if (activeCall) {
            activeCall.mute(isMuted)
        }
    }

    function disconnectAll() {
        if (activeCall) {
            twilioDevice?.disconnectAll()
        }
    }

    function updateToken(deviceToken: string) {
        twilioDevice?.updateToken(deviceToken)
    }

    function destroy() {
        twilioDevice?.removeAllListeners()
        twilioDevice?.destroy()
        twilioDevice = null
    }

    function acceptActiveCall() {
        activeCall?.accept()
    }

    return {
        init,
        updateToken,
        destroy,
        disconnectAll,
        sendDigits,
        toggleMuted,
        acceptActiveCall,
        get isInited() {
            return twilioDevice != null
        },
        get activeCall() {
            return activeCall
        },
        get activeCallSid() {
            return activeCall?.parameters.CallSid || ''
        },
    }
}

<script setup lang="ts">
const props = defineProps<{ error: string }>()

function openError() {
    alert(props.error)
}
</script>
<template>
    <div class="flex w-full items-center justify-between px-4 py-2.5">
        <div class="flex gap-4 items-center">
            <SvgIcon name="warning" class="w-8 h-8 text-main-red" />
            <span class="font-light text-black dark:text-white"> Error </span>
        </div>
        <Button class="text-sm px-3 py-2" @click="openError">Learn More</Button>
    </div>
</template>

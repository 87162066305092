<template>
    <div
        class="flex w-full items-center justify-between p-4"
    >
        <div class="flex gap-4 items-center">
            <SvgIcon name="loader" class="w-10 h-7" />
            <span
                class="font-light text-black dark:text-white"
            >
                Initialization
            </span>
        </div>
        <span class="text-text-gray text-sm font-light">
            Please, wait...
        </span>
    </div>
</template>

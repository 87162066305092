import { LoadingStatus, Status } from '@/types/status'
import { ParticipantCallStatus } from './useSync'

export type AcceptedCallParticipant = {
    isOnHold: boolean
    callStatus: ParticipantCallStatus
    friendlyName: string
    identity: string
    isClient: boolean
} & Status<'loading' | 'loaded' | { error: string }>

export type DialpadState = {
    value: string
}

export type ShortWorker = {
    sid: string
    friendlyName: string
    activityName: string
    available: boolean
    identity: string
}

export type WarmTransferState = {
    workers: ShortWorker[]
    type: 'warm_transfer'
    submitStatus: LoadingStatus['status']
} & LoadingStatus

export type ColdTransferState = {
    taskQueues: any[]
    type: 'cold_transfer'
} & LoadingStatus

export type AcceptedCall = {
    dialpad: DialpadState
    activeContent: null | 'dialpad' | WarmTransferState | ColdTransferState
    isMuted: boolean
    isConferenceAdmin: boolean
    acceptedTimestamp: number
    participants: AcceptedCallParticipant[]
} & LoadingStatus

export function useAcceptedCall() {
    function toggleMuted(acceptedCall: AcceptedCall) {
        acceptedCall.isMuted = !acceptedCall.isMuted
        return acceptedCall
    }

    function setAcceptedCallStatus(
        acceptedCall: AcceptedCall,
        status: LoadingStatus['status']
    ) {
        acceptedCall.status = status
        return acceptedCall
    }

    function setDialpadValue(acceptedCall: AcceptedCall, value: string) {
        acceptedCall.dialpad.value = value
        return acceptedCall
    }

    function toggleDialpadOpened(acceptedCall: AcceptedCall) {
        acceptedCall.activeContent =
            acceptedCall.activeContent === 'dialpad' ? null : 'dialpad'
        return acceptedCall
    }

    function toggleForwarding(acceptedCall: AcceptedCall, taskQueues: any[]) {
        if (
            !acceptedCall.activeContent ||
            typeof acceptedCall.activeContent === 'string' ||
            acceptedCall.activeContent.type === 'warm_transfer'
        ) {
            acceptedCall.activeContent = {
                type: 'cold_transfer',
                status: 'none',
                taskQueues,
            }
        } else {
            acceptedCall.activeContent = null
        }
        return acceptedCall
    }

    function toggleAddParticipant(acceptedCall: AcceptedCall): AcceptedCall {
        if (
            !acceptedCall.activeContent ||
            typeof acceptedCall.activeContent === 'string' ||
            acceptedCall.activeContent.type === 'cold_transfer'
        ) {
            acceptedCall.activeContent = {
                type: 'warm_transfer',
                status: 'loading',
                workers: [],
                submitStatus: 'none',
            }
        } else {
            acceptedCall.activeContent = null
        }
        return acceptedCall
    }

    function setForwardingStatus(
        acceptedCall: AcceptedCall,
        status: LoadingStatus['status']
    ) {
        if (
            !acceptedCall.activeContent ||
            typeof acceptedCall.activeContent === 'string'
        ) {
            return acceptedCall
        }

        acceptedCall.activeContent.status = status
        return acceptedCall
    }

    function setAddParticipantWorkers(
        acceptedCall: AcceptedCall,
        workers: ShortWorker[]
    ): AcceptedCall {
        if (
            !acceptedCall.activeContent ||
            typeof acceptedCall.activeContent === 'string' ||
            acceptedCall.activeContent.type === 'cold_transfer'
        ) {
            return acceptedCall
        }

        acceptedCall.activeContent.workers = workers
        acceptedCall.activeContent.status = 'loaded'
        return acceptedCall
    }

    function setAddParticipantSubmittedStatus(
        acceptedCall: AcceptedCall,
        status: LoadingStatus['status']
    ): AcceptedCall {
        if (
            !acceptedCall.activeContent ||
            typeof acceptedCall.activeContent === 'string' ||
            acceptedCall.activeContent.type === 'cold_transfer'
        ) {
            return acceptedCall
        }

        acceptedCall.activeContent.submitStatus = status
        return acceptedCall
    }

    return {
        toggleMuted,
        setDialpadValue,
        toggleDialpadOpened,
        toggleForwarding,
        setForwardingStatus,
        setAcceptedCallStatus,
        toggleAddParticipant,
        setAddParticipantWorkers,
        setAddParticipantSubmittedStatus,
    }
}

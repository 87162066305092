<script setup lang="ts">
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

const props = defineProps<{
    modelValue?: string
    onValidated?: (error: string) => void
    disabled: boolean
}>()

const phone = ref(props.modelValue || '')
const phoneFormatted = ref('')
const error = ref('')

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue && props.modelValue !== phone.value) {
            phone.value = props.modelValue
        }
    }
)

type PhoneObject = {
    country: {
        name: string
        iso2: string
        dialCode: string
        priority: 0
        areaCodes: null
    }
    countryCallingCode: string
    nationalNumber: string
    number: string
    countryCode: string
    valid: false
    formatted: string
}

function validatePhoneNumber(_number: string, phoneObject: PhoneObject) {
    if (!phone.value) {
        error.value = 'Phone number is required'
    }

    if (phone.value && !phoneObject.valid) {
        error.value = 'Phone number is invalid'
    }

    if (phone.value && phoneObject.valid) {
        error.value = ''
    }

    phoneFormatted.value = phoneObject.number
    $emit('update:modelValue', phoneFormatted.value)
    $emit('validated', error.value)

    props.onValidated?.(error.value)
}

function resetState() {
    error.value = ''
    phone.value = ''
    phoneFormatted.value = ''
}

const $emit = defineEmits<{
    'update:modelValue': [string]
    validated: [string]
    enter: []
}>()

defineExpose({
    resetState,
})

const inputRef = ref<{ focus: () => void }>()

onMounted(() => inputRef.value?.focus())

function onEnter() {
    if (!error.value && phone.value) {
        $emit('enter')
    }
}
</script>
<template>
    <VueTelInput
        :dropdownOptions="{
            showDialCodeInSelection: true,
            showFlags: false,
            showSearchBox: true,
            disabled: true,
            showDialCodeInList: true,
        }"
        mode="national"
        defaultCountry="US"
        :enabledFlags="false"
        v-model="phone"
        @on-input="validatePhoneNumber"
        invalidMsg="Phone number is invalid"
        :validCharactersOnly="true"
        :inputOptions="{
            autocomplete: 'off',
            placeholder: '(242) 603-1222',
        }"
        :disabled="disabled"
        @country-changed="resetState"
        ref="inputRef"
        @enter="onEnter"
    >
        <template #arrow-icon>
            <SvgIcon
                name="chevron"
                class="transition transform h-3 w-3 text-text-gray flex-shrink-0 rotate-180"
            />
        </template>
    </VueTelInput>
</template>

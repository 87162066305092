import { tick } from '@/sleep'
import { Sid, TaskSid } from '@/types/sid'
import { TokenExpiredError } from '@/types/token-expired-error'
import { SyncClient, SyncDocument } from 'twilio-sync'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'
import { useDevice } from './useDevice'

let twilioSyncClient: null | SyncClient = null

let syncDocuments = new Map<string, SyncDocument>()

function getSyncDocumentName(taskSid: Sid) {
    const syncDocumentPrefix = 'syncDoc_'
    return `${syncDocumentPrefix}${taskSid}`
}

export type ParticipantCallStatus =
    | 'initiated'
    | 'ringing'
    | 'in-progress'
    | 'completed'
    | 'busy'
    | 'no-answer'
    | 'canceled'
    | 'failed'

export type SyncDocumentParticipantData = {
    identity: string
    callSid: string
    participantLabel: string
    callStatus: ParticipantCallStatus
    hold: boolean
    isTogglingHoldLoading: boolean
    isConferenceAdmin: boolean
    friendlyName: string
}

export type SyncDocumentParticipants = Record<
    string,
    SyncDocumentParticipantData
>

export type SyncDocumentData = {
    participants: SyncDocumentParticipants
} & {
    conferenceSid: string
    acceptedTimestamp: number
}

export function useSync() {
    const emitter = useTwilioAppEmitter()

    async function init(syncToken: string) {
        await new Promise<void>(async (resolve, reject) => {
            if (twilioSyncClient) {
                twilioSyncClient
                    .updateToken(syncToken)
                    .then(resolve)
                    .catch(reject)
            }

            const logLevel = import.meta.env.DEV ? 'debug' : 'error'

            twilioSyncClient = new SyncClient(syncToken, {
                logLevel,
            })

            twilioSyncClient
                .once('tokenAboutToExpire', () => {
                    reject(
                        new TokenExpiredError(
                            'Sync AccessToken about to expire'
                        )
                    )
                })
                .once('tokenExpired', () =>
                    reject(new TokenExpiredError('Sync AccessToken expired'))
                )
                .once('connectionError', reject)

            await tick()
            resolve()
        })
        addSyncListeners()
    }

    function removeDocFromSet(taskSid: TaskSid) {
        const doc = syncDocuments.get(taskSid)

        if (!doc) {
            return
        }

        doc.removeAllListeners()
        syncDocuments.delete(taskSid)
    }

    function emitDocUpdated(taskSid: TaskSid, doc: SyncDocumentData) {
        emitter.emit('syncDocUpdated', { taskSid, doc })
    }

    async function addParticipant(
        taskSid: string,
        {
            identity,
            callSid,
            participantLabel,
            friendlyName,
        }: {
            identity: string
            callSid: string
            participantLabel: string
            friendlyName: string
        }
    ): Promise<SyncDocumentData> {
        const docName = getSyncDocumentName(taskSid)
        const doc = syncDocuments.get(docName)

        if (!doc) {
            throw new Error(`NO DOCUMENT ${docName}`)
        }

        if ((doc.data as SyncDocumentData).participants[identity]) {
            return doc.data as SyncDocumentData
        }

        const participants = (doc.data as SyncDocumentData).participants
        const newData = {
            ...doc.data,
            participants: {
                ...participants,
                [identity]: {
                    identity,
                    callSid,
                    participantLabel,
                    callStatus: 'initiated',
                    hold: false,
                    friendlyName,
                    isTogglingHoldLoading: false,
                    isConferenceAdmin: false,
                },
            },
        }

        await doc.update(newData)
        return newData as SyncDocumentData
    }

    async function removeParticipant(taskSid: TaskSid, identity: string) {
        if (!twilioSyncClient) {
            throw Error('No sync client initiated')
        }

        const docName = getSyncDocumentName(taskSid)
        const doc = await twilioSyncClient.document(docName)

        return doc.mutate((currentValue: any) => {
            if (currentValue.participants[identity]) {
                delete currentValue.participants[identity]
            }
            return currentValue
        })
    }

    async function updateParticipant(
        originTaskSid: TaskSid,
        identity: string,
        participantData: Partial<SyncDocumentParticipantData>
    ) {
        if (!twilioSyncClient) {
            throw Error('No sync client initiated')
        }

        const docName = getSyncDocumentName(originTaskSid)

        const doc = syncDocuments.get(docName)

        if (!doc) {
            throw Error(`No doc with name ${docName}`)
        }

        doc.mutate((currentValue: any) => {
            if (currentValue.participants[identity]) {
                currentValue.participants[identity] = {
                    ...currentValue.participants[identity],
                    ...participantData,
                }
            }
            return currentValue
        })

        return doc.data as SyncDocumentData
    }

    async function subToDoc(taskSid: TaskSid) {
        if (!twilioSyncClient) {
            throw Error('No sync client initiated')
        }

        const docName = getSyncDocumentName(taskSid)
        const doc = await twilioSyncClient.document(docName)

        syncDocuments.set(docName, doc)

        emitDocUpdated(taskSid, doc.data as SyncDocumentData)

        doc.on('updated', (d) => {
            emitDocUpdated(taskSid, d.data as SyncDocumentData)
            syncDocuments.set(docName, doc)
        })

        doc.on('removed', () => {
            syncDocuments.delete(docName)
            doc.removeAllListeners()
        })
    }

    async function updateWarmTransferParticipantCallStatus(
        originTaskSid: TaskSid,
        identity: string,
        status: ParticipantCallStatus
    ): Promise<SyncDocumentData> {
        const device = useDevice()

        if (!twilioSyncClient) {
            throw Error('No sync client initiated')
        }

        const docName = getSyncDocumentName(originTaskSid)
        const doc = await twilioSyncClient.document(docName)

        syncDocuments.set(docName, doc)

        emitDocUpdated(originTaskSid, doc.data as SyncDocumentData)

        doc.on('updated', (d) => {
            emitDocUpdated(originTaskSid, d.data as SyncDocumentData)
            syncDocuments.set(docName, doc)
        })

        doc.on('removed', () => {
            syncDocuments.delete(docName)
            doc.removeAllListeners()
        })

        doc.mutate((currentValue: any) => {
            if (!device.activeCallSid) {
                console.warn('NO ACTIVE CALL')
                return
            }
            currentValue.participants[identity].callStatus = status
            currentValue.participants[identity].callSid = device.activeCallSid
            return currentValue
        })

        return doc.data as SyncDocumentData
    }

    async function toggleHoldLoading(
        taskSid: string,
        identity: string,
        isTogglingHoldLoading: boolean
    ): Promise<SyncDocumentData> {
        const docName = getSyncDocumentName(taskSid)
        const doc = syncDocuments.get(docName)

        if (!doc) {
            throw Error(`NO DOCUMENT ${docName}`)
        }

        const data = doc.data as SyncDocumentData
        const participant = data.participants[identity]

        if (!participant) {
            console.error('NO PARTICIPANT', identity)
        }

        const newData = {
            ...doc.data,
            participants: {
                ...data.participants,
                [identity]: {
                    ...participant,
                    isTogglingHoldLoading,
                },
            },
        } as SyncDocumentData

        await doc.update(newData)
        return newData
    }

    async function setParticipantHold(
        taskSid: string,
        identity: string,
        hold: boolean
    ): Promise<SyncDocumentData> {
        const docName = getSyncDocumentName(taskSid)
        const doc = syncDocuments.get(docName)

        if (!doc) {
            throw Error(`NO DOCUMENT ${docName}`)
        }

        const data = doc.data as SyncDocumentData
        const participant = data.participants[identity]

        if (!participant) {
            console.error('NO PARTICIPANT', identity)
        }

        const newData = {
            ...doc.data,
            participants: {
                ...data.participants,
                [identity]: {
                    ...participant,
                    hold,
                    isTogglingHoldLoading: false,
                },
            },
        } as SyncDocumentData

        await doc.update(newData)

        return newData
    }

    async function createAndSubscribeToDoc(
        taskSid: TaskSid,
        data: SyncDocumentData
    ): Promise<SyncDocumentData> {
        if (!twilioSyncClient) {
            throw Error('Sync client is not inited')
        }

        const docName = getSyncDocumentName(taskSid)
        const doc = await twilioSyncClient.document(docName)

        emitDocUpdated(taskSid, doc.data as SyncDocumentData)

        doc.on('updated', (d) => {
            emitDocUpdated(taskSid, d.data as SyncDocumentData)
            syncDocuments.set(docName, doc)
        }).on('removed', () => {
            syncDocuments.delete(docName)
            doc.removeAllListeners()
        })

        await doc.set(data)
        syncDocuments.set(docName, doc)

        return doc.data as SyncDocumentData
    }

    function addSyncListeners() {
        twilioSyncClient
            ?.removeAllListeners()
            .on('tokenAboutToExpire', () => emitter.emit('tokenExpired'))
            .on('tokenExpired', () => emitter.emit('tokenExpired'))
            .on('connectionStateChanged', (state) => {
                if (state === 'disconnected') {
                    emitter.emit('sdkDisconnected', 'sync')
                }

                if (state === 'connected') {
                    emitter.emit('sdkConnected', 'sync')
                }
            })
            .on('connectionError', (error) =>
                emitter.emit('sdkError', {
                    error: { message: error.message, code: 0 },
                    sdk: 'sync',
                })
            )

        emitter.on('refreshTokens', ({ syncToken }) => {
            twilioSyncClient?.updateToken(syncToken)
        })
    }

    function updateToken(syncToken: string) {
        twilioSyncClient?.updateToken(syncToken)
    }

    async function destroy() {
        twilioSyncClient?.removeAllListeners()
        await twilioSyncClient?.shutdown()
        twilioSyncClient = null
    }

    return {
        init,
        destroy,
        getDoc(taskSid: TaskSid) {
            return (
                (syncDocuments.get(getSyncDocumentName(taskSid))
                    ?.data as SyncDocumentData) || null
            )
        },
        updateToken,
        createAndSubscribeToDoc,
        toggleHoldLoading,
        setParticipantHold,
        addParticipant,
        updateWarmTransferParticipantCallStatus,
        removeParticipant,
        updateParticipant,
        removeDocFromSet,
        subToDoc,
    }
}

<script lang="ts" setup>
import { useWidgetStore } from '@/composables/useWidgetStore'

const store = useWidgetStore()
</script>
<template>
    <div
        class="widget bg-main-bg border-main-bg-stroke border-solid border rounded-xl"
    >
        <LoadingWidget v-if="store.state.status === 'loading'" />
        <WidgetError
            v-else-if="store.state.status === 'error'"
            :error="store.state.error"
        />
        <template v-else-if="store.state.status === 'loaded'">
            <WidgetDisconnected v-if="store.isAnySdkDisconnected" />
            <template v-else>
                <Header :workerState="store.state" />
                <WidgetContent :workerState="store.state" />
            </template>
        </template>
    </div>
</template>
<style scoped>
.widget {
    width: 300px;
    backdrop-filter: blur(18px);
}
</style>

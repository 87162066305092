<script lang="ts" setup>
const props = defineProps<{
    status?: string
    isSmall?: boolean
    avatar?: string | null
}>()

const sizeClass = computed(() => {
    if (props.isSmall) {
        return 'w-9.5 h-9.5'
    }

    return 'w-14 h-14'
})
</script>
<template>
    <div
        class="flex-shrink-0 rounded-full relative text-icons-gray bg-button flex items-center justify-center"
        :class="sizeClass"
    >
        <div
            class="w-full h-full flex items-center justify-center relative overflow-hidden rounded-full"
        >
            <SvgIcon name="avatar" :class="sizeClass" v-if="!avatar" />
            <img v-else :src="avatar" :class="sizeClass" class="object-cover" />
        </div>
        <div
            class="h-2.5 w-2.5 rounded-full border-2 border-solid absolute top-0 right-0 border-status-border"
            :class="{
                'bg-main-green': status === 'Available',
                'bg-orange': status === 'Unavailable',
                'bg-main-red': status === 'Offline',
            }"
            v-if="status"
        />
    </div>
</template>

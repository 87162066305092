<script lang="ts" setup>
import { Tooltip } from 'floating-vue'
const props = defineProps<{
    delay?: { show?: number; hide?: number }
    placement?: string
}>()

const showDelay = props.delay?.show || 850
const hideDelay = props.delay?.hide || 0

const tooltipRef = ref<InstanceType<typeof Tooltip>>()

defineExpose({
    show: () => tooltipRef.value?.show(),
    hide: () => tooltipRef.value?.hide(),
})
</script>
<template>
    <Tooltip
        :delay="{ show: showDelay, hide: hideDelay }"
        :placement="placement || 'bottom'"
        theme="custom"
        ref="tooltipRef"
    >
        <slot />
        <template #popper>
            <slot name="popper" />
        </template>
    </Tooltip>
</template>

<script lang="ts" setup>
import { AcceptedCallParticipant } from '@/composables/useAcceptedCall'
import { Contact } from '@/types/contact'

const props = defineProps<{
    contact: Contact
    isCurrentParticipantAdmin: boolean
    participant: AcceptedCallParticipant
    shouldShowRemoveParticipant: boolean
}>()

const isLoading = computed(() => props.participant.status === 'loading')

defineEmits<{
    toggleHold: [string]
    removeParticipant: []
    contactClicked: []
}>()

const isAccepted = computed(() => {
    return props.participant.callStatus === 'in-progress'
})
</script>
<template>
    <div class="flex justify-between gap-2 items-center">
        <div class="flex gap-3 items-center">
            <Avatar
                :avatar="participant.isClient ? contact.avatar : null"
                :is-small="true"
            />
            <div class="flex flex-col gap-0.25">
                <div class="flex flex-col">
                    <button
                        class="flex items-center gap-2 hover:underline"
                        @click="$emit('contactClicked')"
                        v-if="
                            participant.isClient && (contact.name || contact.id)
                        "
                    >
                        <span class="text-black dark:text-white">{{
                            participant.friendlyName
                        }}</span>
                        <SvgIcon class="h-3 w-3" name="open-in-new" />
                    </button>
                    <span v-else class="text-black dark:text-white">
                        {{ participant.friendlyName }}
                    </span>
                    <span
                        class="text-text-gray text-xs"
                        v-if="
                            participant.isClient &&
                            participant.friendlyName !== participant.identity
                        "
                        >{{ participant.identity }}</span
                    >
                </div>
                <div
                    class="text-sm flex gap-2 items-center"
                    :class="
                        participant.isOnHold && isAccepted
                            ? 'text-orange'
                            : 'text-black dark:text-white'
                    "
                >
                    <span class="w-16 whitespace-nowrap">
                        <template v-if="participant.isOnHold && isAccepted">
                            On Hold
                        </template>
                        <template
                            v-else-if="participant.callStatus === 'in-progress'"
                        >
                            Live
                        </template>
                        <template
                            v-else-if="
                                participant.callStatus === 'initiated' ||
                                participant.callStatus === 'ringing'
                            "
                        >
                            Calling...
                        </template>
                        <template v-else-if="participant.callStatus === 'busy'">
                            Busy
                        </template>
                        <template
                            v-else-if="participant.callStatus === 'no-answer'"
                        >
                            No Answer
                        </template>
                        <template
                            v-else-if="participant.callStatus === 'canceled'"
                        >
                            Canceled
                        </template>
                        <template
                            v-else-if="participant.callStatus === 'failed'"
                        >
                            Failed
                        </template>
                        <template
                            v-else-if="participant.callStatus === 'completed'"
                        >
                            Completed
                        </template>
                    </span>
                </div>
            </div>
        </div>
        <div class="flex gap-2">
            <Tooltip
                placement="left"
                :disabled="isLoading"
                v-if="participant.isClient || isCurrentParticipantAdmin"
            >
                <IconButton
                    :class="{ 'text-orange': participant.isOnHold }"
                    :icon="isLoading ? 'spinner' : 'hold'"
                    :disabled="isLoading || !isAccepted"
                    @click="$emit('toggleHold', participant.identity)"
                />
                <template #popper>
                    <span>{{
                        participant.isOnHold
                            ? 'Remove from hold'
                            : 'Put on hold'
                    }}</span>
                </template>
            </Tooltip>
            <IconButton
                v-if="
                    shouldShowRemoveParticipant &&
                    (participant.isClient || isCurrentParticipantAdmin)
                "
                color="red"
                :icon="isLoading ? 'spinner' : 'reject'"
                @click="$emit('removeParticipant')"
                :disabled="isLoading || !isAccepted"
            />
        </div>
    </div>
</template>
<style scoped>
.text-orange:deep(.btn-transparent),
.text-orange:deep(.btn-transparent:hover) {
    color: var(--orange);
}
</style>

import { TaskSid } from '@/types/sid'
import { LoadingStatus } from '@/types/status'
import { PhoneNumber } from '@/types/phoneNumber'
import { AcceptedCall } from './useAcceptedCall'
import { Contact } from '@/types/contact'

export type BaseTask = {
    taskSid: TaskSid
    phoneNumber: PhoneNumber
    contact: Contact
    acceptedCall: null | AcceptedCall
    taskQueue: string
} & LoadingStatus

export type IncomingCallTask = {
    type: 'incoming_call'
} & BaseTask

export type OutgoingCallTask = {
    type: 'outgoing_call'
} & BaseTask

export type WarmTransferTask = {
    type: 'warm_transfer'
    transferFrom: string
} & BaseTask

export type WorkerTask = IncomingCallTask | OutgoingCallTask | WarmTransferTask

export function useWorkerTask() {
    function setTaskStatus(
        workerTask: WorkerTask,
        status: LoadingStatus['status']
    ) {
        workerTask.status = status
        return workerTask
    }

    function setAcceptedCall(
        workerTask: WorkerTask,
        acceptedCall: AcceptedCall
    ) {
        workerTask.acceptedCall = acceptedCall
        return workerTask
    }

    function updateAcceptedCallParticipants(
        workerTask: WorkerTask,
        acceptedCall: AcceptedCall
    ) {
        if (workerTask.acceptedCall) {
            workerTask.acceptedCall = {
                ...workerTask.acceptedCall,
                isConferenceAdmin: acceptedCall.isConferenceAdmin,
                participants: acceptedCall.participants,
            }
        } else {
            workerTask.acceptedCall = acceptedCall
        }

        return workerTask
    }

    return {
        setTaskStatus,
        setAcceptedCall,
        updateAcceptedCallParticipants,
    }
}

<script lang="ts" setup>
const props = withDefaults(
    defineProps<{
        color?: 'green' | 'red' | 'transparent'
        icon: string
        active?: boolean
        disabled?: boolean
    }>(),
    {
        color: 'transparent',
    }
)

defineEmits<{
    click: []
}>()

function getButtonClass() {
    if (props.color === 'green') {
        return 'btn-green'
    }

    if (props.color === 'red') {
        return 'btn-red'
    }

    if (props.color === 'transparent') {
        return 'btn-transparent'
    }

    return 'btn-transparent'
}

const buttonClass = getButtonClass()
</script>
<template>
    <button
        class="h-7.5 w-7.5 items-center justify-center flex"
        :class="[
            buttonClass,
            {
                active: active,
            },
        ]"
        @click="$emit('click')"
        :disabled="disabled"
    >
        <SvgIcon class="h-4.25 w-4.25" :name="icon" />
    </button>
</template>

<script lang="ts" setup>
import { WorkerState, useWidgetStore } from '@/composables/useWidgetStore'
import { ShortActivity } from '@/types/activity'
import { type DeepReadonly } from 'vue'

const store = useWidgetStore()

const props = defineProps<{
    state: DeepReadonly<WorkerState>
}>()

defineEmits<{
    updateWorkerActivity: [ShortActivity]
}>()

const isOnline = computed(
    () =>
        store.activities.find(
            (activity) => activity.sid === props.state.activity.sid
        )?.available || false
)

const isLoading = computed(() => props.state.activity.status === 'loading')
const hasError = computed(() => typeof props.state.activity.status === 'object')
</script>
<template>
    <div class="flex flex-col">
        <Menu
            as="div"
            class="relative inline-block text-left"
            v-slot="{ open }"
        >
            <MenuButton
                :disabled="isLoading"
                class="flex items-center gap-1 select-none"
            >
                <span
                    class="text-sm"
                    :class="isOnline ? 'text-main-green' : 'text-orange'"
                >
                    {{ state.activity.friendlyName }}
                </span>
                <Tooltip placement="right" :disabled="isLoading || !hasError">
                    <template v-if="!hasError">
                        <SvgIcon
                            :name="isLoading ? 'spinner' : 'chevron'"
                            class="transition transform h-3 w-3 text-text-gray"
                            :class="{ 'rotate-180': !open }"
                        />
                    </template>
                    <template v-else>
                        <SvgIcon name="error" class="h-3 w-3 text-red-400" />
                    </template>
                    <template #popper>
                        <ErrorMessage :status="state.activity.status" />
                    </template>
                </Tooltip>
            </MenuButton>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    class="p-1 fixed top-3 w-120px border border-main-bg-stroke rounded-md flex flex-col text-sm bg-input"
                >
                    <MenuItem
                        class="w-full hover:bg-button-hover active:bg-button-active px-2 h-9 rounded transition duration-75"
                        v-for="activity in store.activities"
                        :key="activity.sid"
                    >
                        <button
                            class="w-full text-left flex items-center justify-between text-black dark:text-white"
                            @click="$emit('updateWorkerActivity', activity)"
                        >
                            {{ activity.friendlyName }}
                            <SvgIcon
                                class="h-3.5 w-3.5"
                                name="check"
                                v-if="state.activity.sid === activity.sid"
                            />
                        </button>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>
<style scoped>
.w-120px {
    width: 120px;
}
</style>

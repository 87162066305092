<script setup lang="ts">
import type { OutboundDial } from '@/composables/useOutboundDialState'
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { Contact } from '@/types/contact'

const props = defineProps<{
    state: Readonly<OutboundDial>
}>()

const phone = ref(props.state.contact?.phoneNumber || '')
const error = ref('')
const submitted = ref(false)

watch(
    () => props.state.contact,
    () => {
        if (props.state.contact) {
            phone.value = props.state.contact.phoneNumber
        }
    }
)

const emitter = useWidgetEmitter()

function makeACall() {
    submitted.value = true

    if (error.value) {
        return
    }

    emitter.emit('makeOutboundCall', {
        from: props.state.phoneNumber.phoneNumber,
        to: phone.value,
    })
}

const isLoading = computed(() => props.state.status === 'loading')

function closeOutboundDial() {
    emitter.emit('toggleOutgoingDial', false)
}

function inputValue() {
    if (!!error.value || !phone.value) {
        return
    }
    emitter.emit('inputOutgoingDialNumber', phone.value)
}

function clickContact(contact: Contact) {
    emitter.emit('contactClicked', contact)
}
</script>
<template>
    <div class="flex flex-col gap-1 min-h-11 justify-center">
        <button
            class="inline-flex select-none group gap-2 items-center mb-1"
            v-if="state.contact"
            @click="clickContact(state.contact)"
        >
            <Avatar :is-small="true" />
            <span
                class="whitespace-nowrap overflow-hidden overflow-ellipsis hover:underline cursor-pointer"
                >{{ state.contact.name }}</span
            >
            <SvgIcon
                class="h-4 w-4 group-hover:opacity-100 opacity-0 transition-opacity duration-75"
                name="open-in-new"
            />
        </button>
        <div class="flex justify-between items-center gap-2">
            <PhoneInput
                @validated="error = $event"
                v-model="phone"
                @update:modelValue="inputValue()"
                :disabled="isLoading"
                @enter="makeACall"
            />
            <div class="flex gap-2 flex-shrink-0">
                <IconButton
                    :disabled="isLoading"
                    icon="close"
                    @click="closeOutboundDial()"
                />
                <IconButton
                    :disabled="isLoading || !phone || !!error"
                    @click="makeACall"
                    :icon="isLoading ? 'spinner' : 'accept'"
                    color="green"
                />
            </div>
        </div>
    </div>
</template>

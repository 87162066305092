<script lang="ts" setup>
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { WarmTransferTask } from '@/composables/useWorkerTask'
import { Contact } from '@/types/contact'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    task: DeepReadonly<WarmTransferTask>
}>()

const isLoading = computed(() => props.task.status === 'loading')

const emitter = useWidgetEmitter()

function rejectTask() {
    emitter.emit('rejectTask', props.task.taskSid)
}

function acceptTask() {
    emitter.emit('acceptTask', props.task.taskSid)
}

function openContact(contact: Contact) {
    if (!contact.name || !contact.id) {
        return
    }

    emitter.emit('contactClicked', contact)
}
</script>
<template>
    <div class="flex flex-col">
        <div class="flex flex-col items-center gap-2">
            <Avatar :avatar="task.contact.avatar" />
            <div
                class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
            >
                {{ task.transferFrom }}
            </div>
            <span class="text-black dark:text-white text-sm font-light">
                invites you to join the call with
            </span>
            <button
                @click="openContact(task.contact)"
                class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
            >
                <span>{{ task.contact.name || task.contact.phoneNumber }}</span>
                <SvgIcon
                    v-if="task.contact.name"
                    class="h-3 w-3"
                    name="open-in-new"
                />
            </button>
        </div>
        <div class="flex justify-between w-full mt-6">
            <Button
                @click="rejectTask()"
                :icon="isLoading ? 'spinner' : 'reject'"
                color="red"
                :disabled="isLoading"
            >
                Reject
            </Button>
            <Button
                @click="acceptTask()"
                :icon="isLoading ? 'spinner' : 'accept'"
                color="green"
                :disabled="isLoading"
            >
                Accept
            </Button>
        </div>
        <ErrorMessage :status="task.status" />
    </div>
</template>

import { Activity, ShortActivity } from '@/types/activity'
import { Reservation } from '@/types/reservation'
import { TaskSid } from '@/types/sid'
import { Task } from '@/types/task'
import { AgentWarmTransfer, TaskAttributes } from '@/types/task-attributes'
import { TokenExpiredError } from '@/types/token-expired-error'
import { WorkerDetails } from '@/types/worker'
import { useApi } from './useApi'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'

type WorkerAttributes = { contact_uri: string }

let twilioWorker: null | Twilio.TaskRouter.Worker<
    TaskAttributes,
    WorkerAttributes
> = null

type WorkerState = {
    taskReservations: Map<TaskSid, Reservation<TaskAttributes>>
    workerDetails: null | WorkerDetails<WorkerAttributes>
    acceptedTaskSid: null | string
    workerConnectActivitySid: null | string
    activities: Activity[]
}

function initWorkerState(): WorkerState {
    return {
        taskReservations: new Map<TaskSid, Reservation<TaskAttributes>>(),
        workerDetails: null,
        acceptedTaskSid: null,
        workerConnectActivitySid: null,
        activities: [],
    }
}

let workerState: WorkerState = initWorkerState()

export function useWorker() {
    const emitter = useTwilioAppEmitter()
    const api = useApi()

    async function loadWorkerReservations(
        worker: Twilio.TaskRouter.Worker<TaskAttributes, WorkerAttributes>
    ) {
        return new Promise<Reservation<TaskAttributes>[]>((resolve, reject) =>
            worker.fetchReservations((error, reservationsList) => {
                if (error) {
                    return reject(error)
                }

                let filteredReservations = reservationsList.data.filter(
                    (reservation) =>
                        (reservation.reservationStatus === 'accepted' ||
                            reservation.reservationStatus === 'pending') &&
                        reservation.task.assignmentStatus !== 'completed' &&
                        reservation.task.assignmentStatus !== 'canceled'
                )

                filteredReservations.forEach((res) =>
                    workerState.taskReservations.set(
                        res.taskSid as TaskSid,
                        res
                    )
                )

                resolve(filteredReservations)
            })
        )
    }

    function fetchWorkerDetails(
        worker: Twilio.TaskRouter.Worker<TaskAttributes, WorkerAttributes>
    ): Promise<WorkerDetails<WorkerAttributes>> {
        return new Promise((resolve, reject) => {
            if (workerState.workerDetails) {
                return resolve(workerState.workerDetails)
            }

            worker.fetch((error, details) => {
                if (error) {
                    return reject(error)
                }

                if (!workerState.workerDetails) {
                    workerState.workerDetails = details
                }

                resolve(workerState.workerDetails)
            })
        })
    }

    function fetchActivities(
        worker: Twilio.TaskRouter.Worker<TaskAttributes, WorkerAttributes>,
        onACallActivitySid?: string
    ): Promise<any[]> {
        return new Promise((resolve, reject) => {
            worker.activities.fetch((error, response) => {
                if (error) {
                    return reject(error)
                }

                const activities = response.data.filter(
                    (item) => item.sid !== onACallActivitySid
                )

                if (workerState.activities.length) {
                    return resolve(workerState.activities)
                }

                workerState.activities = activities

                resolve(activities)
            })
        })
    }

    type FetchWorkerDataResponse = {
        workerDetails: WorkerDetails<WorkerAttributes>
        activities: Activity[]
        reservations: Reservation<TaskAttributes>[]
    }

    async function fetchWorkerData(
        onACallActivitySid?: string
    ): Promise<FetchWorkerDataResponse> {
        const [workerDetails, activities, reservations] = await Promise.all([
            fetchWorkerDetails(twilioWorker!),
            fetchActivities(twilioWorker!, onACallActivitySid),
            loadWorkerReservations(twilioWorker!),
        ])

        return {
            workerDetails,
            activities,
            reservations,
        }
    }

    async function init(
        token: string,
        params: {
            connectActivitySid?: string
            disconnectActivitySid?: string
            onACallActivitySid?: string
        }
    ): Promise<FetchWorkerDataResponse> {
        return new Promise<FetchWorkerDataResponse>((resolve, reject) => {
            const {
                connectActivitySid,
                disconnectActivitySid,
                onACallActivitySid,
            } = params

            workerState.workerConnectActivitySid = connectActivitySid || null

            if (twilioWorker) {
                twilioWorker.updateToken(token)
                addWorkerListeners()
                return fetchWorkerData(params.onACallActivitySid)
                    .then(resolve)
                    .catch(reject)
            }

            twilioWorker = new Twilio.TaskRouter.Worker(
                token,
                import.meta.env.DEV,
                connectActivitySid,
                disconnectActivitySid,
                true
            )

            twilioWorker
                .once('token.expired', () =>
                    reject(new TokenExpiredError('Worker AccessToken expired'))
                )
                .once('error', reject)

            /**
             * Once first activity.update comes moving worker from offline to available
             * we then fetch worker data and resolve
             */
            addWorkerListeners()
            fetchWorkerData(onACallActivitySid).then(resolve).catch(reject)
        })
    }

    function updateActivity(sid: string) {
        return new Promise<
            Twilio.TaskRouter.Worker<TaskAttributes, WorkerAttributes>
        >((resolve, reject) => {
            twilioWorker?.update({ ActivitySid: sid }, (error, result) => {
                if (error) {
                    return reject(error)
                }

                resolve(result)
            })
        })
    }

    function updateToken(workerToken: string) {
        twilioWorker?.updateToken(workerToken)
    }

    function setReservationTask(task: Task<TaskAttributes>) {
        const reservation = workerState.taskReservations.get(
            task.sid as TaskSid
        )

        if (!reservation) {
            return
        }

        reservation.task = task
        return upsertReservation(reservation)
    }

    function upsertReservation(reservation: Reservation<TaskAttributes>) {
        workerState.taskReservations.set(
            reservation.taskSid as TaskSid,
            reservation
        )
        return reservation
    }

    function removeReservation(reservation: Reservation<TaskAttributes>) {
        workerState.taskReservations.delete(reservation.taskSid as TaskSid)
    }

    function removeTask(task: Task<TaskAttributes>) {
        const reservation = workerState.taskReservations.get(
            task.sid as TaskSid
        )

        if (!reservation) {
            return
        }

        workerState.taskReservations.delete(reservation.taskSid as TaskSid)
        return reservation
    }

    function addWorkerListeners() {
        twilioWorker
            ?.removeAllListeners()
            .on('token.expired', () => emitter.emit('tokenExpired'))
            .on('activity.update', (worker) => {
                workerState.workerDetails = worker
                emitter.emit('workerUpdated', worker)
            })
            .on('ready', () => emitter.emit('sdkConnected', 'worker'))
            .on('error', (error) =>
                emitter.emit('sdkError', { error, sdk: 'worker' })
            )
            .on('connected', () => emitter.emit('sdkConnected', 'worker'))
            .on('disconnected', () => {
                // Set message on disconnected
                // console.log()
                emitter.emit('sdkDisconnected', 'worker')
            })
            .on('reservation.created', (reservation) => {
                upsertReservation(reservation)
                emitter.emit(
                    'reservationCreated',
                    reservation,
                    workerState.workerDetails!
                )
            })
            .on('reservation.accepted', (reservation) => {
                upsertReservation(reservation)
                emitter.emit(
                    'reservationAccepted',
                    reservation,
                    workerState.workerDetails!
                )
            })
            .on('reservation.canceled', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('reservation.completed', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('reservation.rejected', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('reservation.rescinded', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('reservation.timeout', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('reservation.wrapup', (reservation) => {
                removeReservation(reservation)
                emitter.emit('reservationRemoved', reservation)
            })
            .on('task.updated', (task) => {
                const reservation = setReservationTask(task)

                if (!reservation) {
                    return
                }

                emitter.emit(
                    'reservationUpdated',
                    reservation,
                    workerState.workerDetails!
                )
            })
            .on('task.completed', (task) => {
                const reservation = removeTask(task)

                if (!reservation) {
                    return
                }

                emitter.emit('reservationRemoved', reservation)
            })
            .on('task.canceled', (task) => {
                const reservation = removeTask(task)

                if (!reservation) {
                    return
                }

                emitter.emit('reservationRemoved', reservation)
            })
            .on('task.deleted', (task) => {
                const reservation = removeTask(task)

                if (!reservation) {
                    return
                }

                emitter.emit('reservationRemoved', reservation)
            })

        emitter.on('refreshTokens', ({ workerToken }) => {
            twilioWorker?.updateToken(workerToken)
        })
    }

    function callWarmTransfer(
        reservation: Reservation<AgentWarmTransfer>,
        identity: string
    ): Promise<Reservation<AgentWarmTransfer>> {
        return new Promise((resolve, reject) => {
            const { __origin_task_sid, from } = reservation.task.attributes

            const params = {
                task_sid: __origin_task_sid,
                transferred_task_sid: reservation.task.sid,
            }

            reservation.call(
                from,
                api.getWarmTransferCallUrl(params),
                api.getConferenceParticipantStatusCallback(params),
                'true',
                'false',
                `${identity}?__accept=true`,
                (error, reservation) => {
                    if (error) {
                        return reject(error)
                    }

                    resolve(reservation)
                }
            )
        })
    }

    function inboundCallConference(
        reservation: Reservation<TaskAttributes>,
        identity: string
    ) {
        return new Promise<Reservation<TaskAttributes>>((resolve, reject) => {
            reservation.conference(
                undefined,
                undefined,
                undefined,
                `${identity}?__accept=true`,
                async (error, reservation) => {
                    if (error) {
                        return reject(error)
                    }

                    resolve(reservation)
                },
                {
                    beep: 'false',
                    earlyMedia: 'true',
                    StatusCallback: api.getConferenceParticipantStatusCallback({
                        task_sid: reservation.task.sid,
                    }),
                    StatusCallbackEvent: 'completed',
                    ConferenceStatusCallback: api.getConferenceStatusCallback(),
                    ConferenceStatusCallbackEvent:
                        'start,end,join,leave,mute,hold',
                    EndConferenceOnExit: 'false',
                    EndConferenceOnCustomerExit: 'false',
                    RecordingChannels: 'dual',
                    Record: 'true',
                    ConferenceRecord: 'record-from-start',
                }
            )
        })
    }

    async function acceptReservation(sid: TaskSid) {
        const { workerDetails, taskReservations } = workerState

        if (!workerDetails) {
            throw Error(`Worker Deatils not fetched`)
        }

        const reservation = taskReservations.get(
            sid
        ) as Reservation<TaskAttributes>

        if (!reservation) {
            throw Error(`Reservation with task ${sid} not found`)
        }

        const { __type } = reservation.task.attributes

        if (__type === 'outgoing_call') {
            return new Promise<Reservation<TaskAttributes>>(
                (resolve, reject) => {
                    reservation.accept((error, res) => {
                        if (error) {
                            return reject(error)
                        }

                        resolve(res)
                    })
                }
            )
        }

        if (reservation.task.attributes.__type === 'warm_transfer') {
            return callWarmTransfer(
                reservation as Reservation<AgentWarmTransfer>,
                workerDetails.attributes.contact_uri
            )
        }

        return inboundCallConference(
            reservation,
            workerDetails.attributes.contact_uri
        )
    }

    function addRejectedWorkerToTask(reservation: Reservation<TaskAttributes>) {
        const rejectedWorkers =
            reservation.task.attributes.__rejected_workers || []

        return new Promise<void>((resolve, reject) => {
            reservation.task.update(
                {
                    Attributes: {
                        ...reservation.task.attributes,
                        __rejected_workers: [
                            ...rejectedWorkers,
                            twilioWorker!.workerSid,
                        ],
                    },
                },
                (error, _task) => {
                    if (error) {
                        return reject(error)
                    }

                    resolve()
                }
            )
        })
    }

    async function rejectReservation(
        sid: TaskSid,
        unavailableActivitySid?: string
    ) {
        const { taskReservations } = workerState

        const reservation = taskReservations.get(
            sid
        ) as Reservation<TaskAttributes>

        if (!reservation) {
            throw Error(`Reservation with ${sid} not found`)
        }

        await addRejectedWorkerToTask(reservation)
        return new Promise<void>((resolve, reject) => {
            reservation.reject(unavailableActivitySid, (error) => {
                if (error) {
                    return reject(error)
                }

                resolve()
            })
        })
    }

    async function completeReservation(sid: TaskSid) {
        const { taskReservations } = workerState

        const reservation = taskReservations.get(
            sid
        ) as Reservation<TaskAttributes>

        if (!reservation) {
            throw Error(`Reservation with ${sid} not found`)
        }

        return new Promise<Reservation<TaskAttributes>>((resolve, reject) => {
            reservation.task.complete((error, task) => {
                if (error) {
                    return reject(error)
                }

                reservation.task = task
                resolve(reservation)
            })
        })
    }

    function destroy() {
        twilioWorker?.removeAllListeners()
        workerState = initWorkerState()
    }

    async function goToAvailable(): Promise<ShortActivity | void> {
        const availableActivity = workerState.activities.find(
            (activity) => activity.available
        )

        if (!availableActivity) {
            return Promise.resolve()
        }

        await updateActivity(availableActivity.sid)
        return availableActivity
    }

    return {
        init,
        updateActivity,
        updateToken,
        rejectReservation,
        acceptReservation,
        destroy,
        completeReservation,
        getTaskBySid(taskSid: TaskSid): Task<TaskAttributes> {
            const task = workerState.taskReservations.get(taskSid)?.task

            if (!task) {
                throw Error(`No task with ${taskSid} in state`)
            }

            return task
        },
        get isAvailable() {
            return workerState.workerDetails
                ? workerState.workerDetails.available
                : true
        },
        get workerDetails(): {
            sid: string
            identity: string
            friendlyName: string
        } {
            if (!workerState.workerDetails) {
                throw Error('No worker details in state')
            }

            return {
                sid: workerState.workerDetails.sid,
                friendlyName: workerState.workerDetails.friendlyName,
                identity: workerState.workerDetails.attributes.contact_uri,
            }
        },
        goToAvailable,
    }
}

<script setup lang="ts">
const props = defineProps<{
    initialTime: number
}>()

const time = ref(props.initialTime)
const interval = ref<any>(null)

const startTimer = () => {
    interval.value = setInterval(() => {
        time.value = time.value + 1
    }, 1000)
}

const addZero = (value: number) => {
    return value >= 10 ? value : `0${value}`
}

const getTime = computed(() => {
    const days = Math.floor(time.value / (3600 * 24))
    const hours = Math.floor((time.value / 3600) % 24)
    const minutes = Math.floor((time.value % 3600) / 60)
    const seconds = Math.floor(time.value % 60)

    if (days) {
        return `${days}d ${hours}h`
    }

    if (hours) {
        return `${hours}h ${minutes} min`
    }

    return `${addZero(minutes)}:${addZero(seconds)}`
})

onMounted(startTimer)

onUnmounted(() => {
    if (typeof interval.value !== 'number') {
        return
    }

    clearInterval(interval.value)
})
</script>
<template>
    <span class="text-text-gray text-sm font-light w-9 flex-shrink-0">
        {{ getTime }}
    </span>
</template>

<script lang="ts" setup>
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { WorkerState } from '@/composables/useWidgetStore'
import { ShortActivity } from '@/types/activity'
import { type DeepReadonly } from 'vue'
import Tooltip from './Tooltip.vue'

const props = defineProps<{
    state: DeepReadonly<WorkerState>
}>()

const emitter = useWidgetEmitter()
const tooltipRef = ref<InstanceType<typeof Tooltip>>()
const timeoutId = ref<any>(null)

function showSwitchToAvailableAlert() {
    tooltipRef.value?.show()
    if (timeoutId.value) {
        clearTimeout(timeoutId.value)
    }

    timeoutId.value = setTimeout(() => {
        tooltipRef.value?.hide()
        timeoutId.value = null
    }, 3000)
}

emitter.on('showSwitchToAvailableAlert', showSwitchToAvailableAlert)

onBeforeMount(() => {
    emitter.on('showSwitchToAvailableAlert', showSwitchToAvailableAlert)
})

onBeforeUnmount(() => {
    emitter.off('showSwitchToAvailableAlert', showSwitchToAvailableAlert)
})

function updateWorkerActivity(activity: ShortActivity) {
    emitter.emit('updateActivity', activity)
}

function openOutgoingCall() {
    emitter.emit('toggleOutgoingDial', true)
}

const isOnline = computed(() => props.state.activity.available)

watch(isOnline, () => {
    if (isOnline.value && timeoutId.value) {
        tooltipRef.value?.hide()
        timeoutId.value = null
        clearTimeout(timeoutId.value)
    }
})
</script>
<template>
    <div class="flex items-center justify-between">
        <div class="flex gap-3 items-center flex-grow w-full overflow-hidden">
            <Avatar isSmall :status="state.activity.friendlyName" />
            <div class="flex flex-col flex-grow pr-2 overflow-hidden">
                <span
                    class="font-light text-black dark:text-white whitespace-nowrap w-full overflow-hidden overflow-ellipsis"
                >
                    {{ state.name }}
                </span>
                <ActivityDropdown
                    :state="state"
                    @updateWorkerActivity="updateWorkerActivity"
                />
            </div>
        </div>
        <div class="flex gap-2 flex-shrink-0">
            <Tooltip ref="tooltipRef" placement="left">
                <IconButton
                    :disabled="!isOnline"
                    icon="outgoing"
                    @click="openOutgoingCall"
                />
                <template #popper>
                    <span v-if="isOnline">Make outgoing call</span>
                    <span v-else>Switch to available to make call</span>
                </template>
            </Tooltip>
        </div>
    </div>
</template>

import { TaskAttributes } from '@/types/task-attributes'
import { Task } from '@/types/task'
import { SyncDocumentData, useSync } from './useSync'
import { TaskSid } from '@/types/sid'
import { AcceptedCall, AcceptedCallParticipant } from './useAcceptedCall'
import { Contact } from '@/types/contact'

export function useSyncDocToAcceptedCall() {
    const sync = useSync()

    function getIncomingCallSyncDocument(
        task: Task<TaskAttributes>,
        workerIdentity: string,
        workerFriendlyName: string,
        contact?: Contact
    ) {
        const { attributes } = task

        switch (attributes.__type) {
            case 'warm_transfer': {
                return sync.updateWarmTransferParticipantCallStatus(
                    attributes.__origin_task_sid,
                    workerIdentity,
                    'in-progress'
                )
            }
            case 'incoming_call':
            case 'transfer_to_queue': {
                const getCustomerIdentity = () => {
                    if (attributes.__type === 'incoming_call') {
                        return attributes.from
                    }

                    if (
                        attributes.__type === 'transfer_to_queue' &&
                        attributes.__original_call_direction === 'incoming_call'
                    ) {
                        return attributes.from
                    }

                    return attributes.to
                }

                const customer = getCustomerIdentity()

                return sync.createAndSubscribeToDoc(
                    task.sid as TaskSid,
                    {
                        conferenceSid: attributes.conference.sid,
                        acceptedTimestamp: Date.now(),
                        participants: {
                            [workerIdentity]: {
                                callSid:
                                    attributes.conference.participants.worker,
                                callStatus: 'in-progress',
                                isConferenceAdmin: true,
                                isTogglingHoldLoading: false,
                                hold: false,
                                identity: workerIdentity,
                                participantLabel: 'Agent',
                                friendlyName: workerFriendlyName,
                            },
                            [customer]: {
                                callSid:
                                    attributes.conference.participants.customer,
                                callStatus: 'in-progress',
                                isConferenceAdmin: false,
                                isTogglingHoldLoading: false,
                                hold: false,
                                identity: customer,
                                friendlyName: contact?.name || customer,
                                participantLabel: 'Customer',
                            },
                        },
                    } as SyncDocumentData
                )
            }
            default:
                throw Error(`Not implemented for ${attributes.__type} calls`)
        }
    }

    async function incomingCallAccepted(
        task: Task<TaskAttributes>,
        workerIdentity: string,
        workerFriendlyName: string,
        contact?: Contact
    ) {
        const syncDoc = await getIncomingCallSyncDocument(
            task,
            workerIdentity,
            workerFriendlyName,
            contact
        )
        return initAcceptedCall(syncDoc, workerIdentity)
    }

    function initAcceptedCall(
        data: SyncDocumentData,
        workerIdentity: string
    ): AcceptedCall {
        const isConferenceAdmin =
            data.participants[workerIdentity]?.isConferenceAdmin || false

        const participants: AcceptedCallParticipant[] = Object.values(
            data.participants
        )
            .filter((participant) => participant.identity !== workerIdentity)
            .sort((left) => (left.participantLabel === 'Customer' ? -1 : 1))
            .map((participant) => ({
                isOnHold: participant.hold,
                callStatus: participant.callStatus,
                friendlyName: participant.friendlyName,
                status: participant.isTogglingHoldLoading
                    ? 'loading'
                    : 'loaded',
                isClient: participant.participantLabel === 'Customer',
                identity: participant.identity,
            }))

        return {
            dialpad: { value: '' },
            activeContent: null,
            isMuted: false,
            isConferenceAdmin,
            acceptedTimestamp: data.acceptedTimestamp,
            participants,
            status: 'none',
        }
    }

    function getParticipantData(taskSid: TaskSid, identity: string) {
        const doc = sync.getDoc(taskSid)

        if (!doc) {
            throw Error(`No doc found with ${taskSid}`)
        }

        const participant = doc.participants[identity]

        if (!participant) {
            throw Error(`No participant found ${identity}`)
        }

        return { conferenceSid: doc.conferenceSid, participant }
    }

    function getCustomerParticipantData(taskSid: TaskSid) {
        const doc = sync.getDoc(taskSid)

        if (!doc) {
            throw Error(`No doc found with ${taskSid}`)
        }

        const participant = Object.values(doc.participants).find(
            (participant) => participant.participantLabel === 'Customer'
        )

        if (!participant) {
            throw Error(`No customert participant found`)
        }

        return { conferenceSid: doc.conferenceSid, participant }
    }

    function getCustomerCallSid(taskSid: TaskSid) {
        try {
            const data = getCustomerParticipantData(taskSid)
            return data.participant.callSid
        } catch (err) {
            return null
        }
    }

    function removeWorkerParticipant(taskSid: TaskSid, identity: string) {
        return sync.removeParticipant(taskSid, identity)
    }

    return {
        incomingCallAccepted,
        initAcceptedCall,
        getParticipantData,
        getCustomerParticipantData,
        removeWorkerParticipant,
        getCustomerCallSid,
    }
}

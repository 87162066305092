<script lang="ts" setup>
import type { DialpadState } from '@/composables/useAcceptedCall'

type DialpadButton = {
    number: string | number
    sub: string
}

const props = defineProps<{
    state: DialpadState
}>()

const lastInput = ref(0)
const currentTarget = ref<DialpadButton | null>(null)

const emit = defineEmits<{
    inputDialpadValue: [string]
}>()

const dialpadButtons: DialpadButton[][] = [
    [
        { number: 1, sub: '' },
        { number: 2, sub: '' },
        { number: 3, sub: '' },
    ],
    [
        { number: 4, sub: '' },
        { number: 5, sub: '' },
        { number: 6, sub: '' },
    ],
    [
        { number: 7, sub: '' },
        { number: 8, sub: '' },
        { number: 9, sub: '' },
    ],
    [
        { number: '*', sub: '' },
        { number: 0, sub: '+' },
        { number: '#', sub: '' },
    ],
]

const trackInput = (item: DialpadButton) => {
    currentTarget.value = item
    lastInput.value = Date.now()
}

const addInput = (item: DialpadButton) => {
    const diff = differenceInMilliseconds(Date.now(), lastInput.value)
    const value =
        diff >= 650 && item.number === 0 ? '+' : item.number.toString()
    emit('inputDialpadValue', props.state.value + value)
    currentTarget.value = null
    lastInput.value = 0
}
</script>
<template>
    <div>
        <div
            class="text-center h-10 mt-2 text-2xl font-medium text-black dark:text-white overflow-hidden overflow-ellipsis"
        >
            {{ state.value }}
        </div>
        <div
            class="flex gap-5 flex-col w-full items-center justify-center py-4"
        >
            <div
                class="flex gap-5 w-full items-center justify-center"
                v-for="(row, i) in dialpadButtons"
                :key="i"
            >
                <button
                    v-for="(item, j) in row"
                    :key="j"
                    class="cursor-pointer select-none h-14 w-14 rounded-full flex flex-col items-center justify-center bg-input border-solid border border-main-bg-stroke"
                    @mousedown="trackInput(item)"
                    @mouseup="addInput(item)"
                >
                    <span class="font-medium text-black dark:text-white">{{
                        item.number
                    }}</span>
                    <span
                        class="uppercase text-text-gray text-xxs font-light"
                        v-if="item.sub"
                        >{{ item.sub }}</span
                    >
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { LoadingStatus } from '@/types/status'

const props = defineProps<{
    status: LoadingStatus['status']
    errorMessage?: string
}>()

const errorMessage = computed(
    () =>
        (typeof props.status === 'object' && props.status.error) ||
        props.errorMessage
)
</script>
<template>
    <span v-if="errorMessage" class="text-sm text-red-400">{{
        errorMessage
    }}</span>
</template>

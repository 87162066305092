<script setup lang="ts">
function reload() {
    window.location.reload()
}
</script>
<template>
    <div class="flex w-full items-center justify-between px-4 py-2.5">
        <div class="flex gap-4 items-center">
            <div class="flex-shrink-0">
                <SvgIcon name="no-connection" class="w-8 h-8 text-main-red" />
            </div>
            <span class="font-light text-xs text-black dark:text-white">
                You are disconnected
            </span>
        </div>
        <Button class="text-sm px-3 py-2" @click="reload">Reload</Button>
    </div>
</template>

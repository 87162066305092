<script lang="ts" setup>
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import type { WorkerTask } from '@/composables/useWorkerTask'
import { Contact } from '@/types/contact'
import { type DeepReadonly } from 'vue'

const props = defineProps<{
    task: DeepReadonly<WorkerTask>
}>()

const isLoading = computed(() => props.task.status === 'loading')

const emitter = useWidgetEmitter()

function cancelCall() {
    emitter.emit('cancelCall', props.task.taskSid)
}

function openContact(contact: Contact) {
    emitter.emit('contactClicked', contact)
}
</script>
<template>
    <div class="flex flex-col">
        <div class="flex flex-col items-center gap-2">
            <Avatar :avatar="task.contact.avatar" />
            <div class="flex flex-col items-center gap-1">
                <button
                    class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
                    v-if="task.contact.name"
                    @click="openContact(task.contact)"
                >
                    <span>{{ task.contact.name }}</span>
                    <SvgIcon class="h-4 w-4" name="open-in-new" />
                </button>
                <div
                    class="text-black dark:text-white text-sm font-light flex gap-2 items-center"
                >
                    {{ task.contact.phoneNumber }}
                </div>
            </div>
            <span class="text-black dark:text-white text-sm font-light">
                <span class="text-text-gray">via</span>
                {{ task.phoneNumber.friendlyName }}
            </span>
        </div>
        <div class="flex justify-center w-full mt-6">
            <Button
                @click="cancelCall()"
                :icon="isLoading ? 'spinner' : 'reject'"
                color="red"
                :disabled="isLoading"
            >
                Reject
            </Button>
        </div>
        <ErrorMessage :status="task.status" />
    </div>
</template>

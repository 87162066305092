<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        value: string
        modelValue?: string
        name?: string
        label?: string
        disabled?: boolean
    }>(),
    { modelValue: '' }
)

function onChange(event: Event) {
    if (!event.target) {
        return
    }

    $emit('update:modelValue', props.value)
}

const $emit = defineEmits<{
    'update:modelValue': [string]
}>()

const isChecked = computed(() => props.modelValue === props.value)
</script>
<template>
    <label
        class="inline-flex gap-3 items-center select-none group"
        :class="disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
    >
        <div
            class="flex items-center justify-center h-3.5 w-3.5 rounded-full border-button-active border-solid border transition duration-100 flex-shrink-0"
            :class="[
                isChecked ? 'border-icons-gray' : 'border-button-active',
                { 'group-hover:border-icons-gray cursor-pointer': !disabled },
            ]"
        >
            <div
                class="h-1.5 w-1.5 rounded-full transition duration-100"
                :class="isChecked ? 'bg-icons-gray' : 'bg-transparent'"
            />
        </div>
        <span
            v-if="label"
            class="text-sm font-light"
            :class="isChecked ? 'text-black dark:text-white' : 'text-text-gray'"
        >
            {{ label }}
        </span>
        <input
            :disabled="disabled"
            :value="value"
            :checked="isChecked"
            @change="onChange($event)"
            type="radio"
            class="hidden"
            :name="name"
        />
    </label>
</template>

<script setup lang="ts">
import type { OutboundDial } from '@/composables/useOutboundDialState'
import { useWidgetEmitter } from '@/composables/useWidgetEmitter'
import { PhoneNumber } from '@/types/phoneNumber'

const props = defineProps<{
    state: OutboundDial
    phoneNumbers: Readonly<PhoneNumber[]>
}>()

const isLoading = computed(() => props.state.status === 'loading')

const emitter = useWidgetEmitter()

function selectPhoneNumber(phoneNumber: PhoneNumber) {
    emitter.emit('selectOutgoingPhoneNumber', phoneNumber)
}
</script>
<template>
    <Menu
        as="div"
        class="relative inline-block text-left w-full"
        v-slot="{ open }"
    >
        <MenuButton
            :disabled="isLoading"
            class="flex items-center gap-1 select-none max-w-full overflow-hidden mx-auto"
        >
            <span
                class="text-sm whitespace-nowrap overflow-hidden overflow-ellipsis text-black dark:text-white"
            >
                {{ state.phoneNumber.friendlyName }}
            </span>
            <SvgIcon
                :name="isLoading ? 'spinner' : 'chevron'"
                class="transition transform h-3 w-3 text-text-gray flex-shrink-0"
                :class="{ 'rotate-180': !open }"
            />
        </MenuButton>
        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                class="p-1 max-h-28 overflow-auto absolute -top-2 w-44 border border-main-bg-stroke rounded-md flex flex-col text-sm bg-input -right-2 shadow-sm"
            >
                <MenuItem
                    class="w-full flex-shrink-0 hover:bg-button-hover active:bg-button-active px-2 h-9 rounded transition duration-75"
                    v-for="(phoneNumber, i) in phoneNumbers"
                    :key="i"
                >
                    <button
                        class="w-full text-left flex items-center justify-between text-black dark:text-white whitespace-nowrap overflow-hidden"
                        @click="selectPhoneNumber(phoneNumber)"
                    >
                        <span class="overflow-ellipsis overflow-hidden">{{
                            phoneNumber.friendlyName
                        }}</span>
                        <SvgIcon
                            class="h-3.5 w-3.5 flex-shrink-0"
                            name="check"
                            v-if="
                                phoneNumber.phoneNumber ===
                                state.phoneNumber.phoneNumber
                            "
                        />
                    </button>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>
<style scoped>
.w-120px {
    width: 120px;
}
</style>
